import React from 'react'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

class StyledInput extends React.PureComponent {
    render() {
        return (
            <Box width='100%' margin='0px' padding='4px 0px 4px 0px' style={{ cursor: 'pointer' }}>
                <StyledTextField { ...this.props } style={{ cursor: 'pointer' }} />
            </Box>
        )
    }
}

// Styled Components
const StyledTextField = withStyles({
    root: {
        cursor: 'pointer',
        '& .MuiFormLabel-root': {
            color: 'rgba(45, 219, 172, 0.8)',
            fontSize: '14px'
        },
        '& label.Mui-focused': {
            color: 'rgba(45, 219, 172, 0.8)'
        },
        '& .MuiOutlinedInput-root': {
            color: '#fff',
            fontSize: '12px',
            fontWeight: '100',
            '& fieldset': {
                borderColor: 'rgba(45, 219, 172, 0.4)'
            },
            '&:hover fieldset': {
                borderColor: 'rgba(45, 219, 172, 0.8)'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(45, 219, 172, 0.8)'
            }
        }
    }
})(TextField)

export default StyledInput