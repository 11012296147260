import React from 'react'

// Import Component
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'

import StyledImages from '../../common/StyledImages'
import StyledInput from '../../common/StyledInput'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'
import { updateRoutePoint } from '../../../store/actions/companyDashboardActions'

export default class UpdateUserRoutePoint extends React.PureComponent {
    render() {
        const { routePointInfo } = this.props        
        return (
            <Box>
                {
                    routePointInfo && routePointInfo.images ?
                    (    
                        <Box style={ containerStyles }>
                            <Box>
                                <StyledImages images={ routePointInfo.images } />
                            </Box>
                            <Box width='100%'>
                                <RoutePointInfoForm routePointId={ routePointInfo.routePointId } />
                            </Box>
                        </Box>
                    )
                        :
                    (
                        <Box>{ 'Data unavailable' }</Box>
                    )    
                }
            </Box>
        )
    }
}

class RoutePointInfoForm extends React.PureComponent {
    state = {
        selectedRoute: -1,
        name: '',
        address: '',
        type: '',
        subtype: '',
        ownerName: '',
        area: '',

        isSnackbarOpen: false,        
        isUpdateUserRouteSuccessful: false,
        feedbackMessage: '',
        loading: false
    }

    // Handle On Change
    _onChange = e => {
        this.setState({ [ e.target.name ]: e.target.value })
    }

    // Hanlde On Submit
    _onSubmit = e => {
        e.preventDefault()

        // Set loading
        this.setState({ loading: true })

        const { name, ownerName, address, area, type, subtype } = this.state
        const { routePointId } = this.props
        if (name && address && area && routePointId) {

            updateRoutePoint({ name, address, area, ownerName, type, subtype }, routePointId)
                .then(res => {
                    if (res.status === 200) {
                        this.setState({
                            isSnackbarOpen: true,
                            isUpdateUserRouteSuccessful: res.status === 200,
                            feedbackMessage: res.message,
                            selectedRoute: -1,
                            name: '',
                            address: '',
                            type: '',
                            subtype: '',
                            ownerName: '',
                            area: '',

                        })
    
                        // Set Loading
                        this.setState({ loading: false })
                    } else {
                        this.setState({
                            isSnackbarOpen: true,
                            isUpdateUserRouteSuccessful: false,
                            feedbackMessage: 'Something went wrong.',
                            loading: false,
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        isSnackbarOpen: true,
                        isUpdateUserRouteSuccessful: false,
                        feedbackMessage: 'Something went wrong.',
                        loading: false,
                        isDialogOpen: false
                    })
                })
        } else {
            this.setState({
                isSnackbarOpen: true,
                isUpdateUserSuccessful: false,
                feedbackMessage: 'Fill up the form properly.',
                loading: false
            })
        }
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }
    render() {
        const { selectedRoute, name, address, type, subtype, ownerName, area, isSnackbarOpen, isUpdateUserRouteSuccessful, feedbackMessage, loading } = this.state
        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }
                <Paper elevation={4} style={ paperStyles }>
                    <Typography
                        component='h1'
                        variant='h4'
                        align='center'
                        display='block'
                        style={{
                            color: '#505050',
                            marginBottom: '2rem'
                        }}
                    >
                        { 'Update Route Point' }
                    </Typography>
                    <form id='update-route-point' onSubmit={ this._onSubmit } style={ formStyles } >
                        <Grid container={true} spacing={4}>
                            
                            <Grid item={ true } xs={ 12 } sm={ 6 } md={ 12 }>
                                <InputLabel>{ 'Select Route' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={[ { value: -1, label: 'None' }, ]}
                                    label='Select Route'
                                    name='selectedRoute'
                                    required={ true }
                                    value={ selectedRoute }
                                    onChange={ this._onChange }
                                />
                            </Grid>
                            
                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Shop Name' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='name'
                                    type='text'
                                    value={ name }
                                    onChange={ this._onChange }
                                    placeholder='Enter name...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Owner Name' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='ownerName'
                                    type='text'
                                    value={ ownerName }
                                    onChange={ this._onChange }
                                    placeholder='Enter owner name...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel>{ 'Address' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='address'
                                    type='text'
                                    value={ address }
                                    onChange={ this._onChange }
                                    placeholder='Enter address...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel>{ 'Area' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='area'
                                    type='text'
                                    value={ area }
                                    onChange={ this._onChange }
                                    placeholder='Enter area...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Type' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='type'
                                    type='text'
                                    value={ type }
                                    onChange={ this._onChange }
                                    placeholder='Enter type...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Subtype' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='subtype'
                                    type='text'
                                    value={ subtype }
                                    onChange={ this._onChange }
                                    placeholder='Enter subtype...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>


                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <StyledSubmitButton
                                    form='update-route-point'
                                    type='submit'
                                    fullWidth={ true }
                                    variant='contained'
                                    onClick={ this._onSubmit }
                                >
                                    { 'Update Route' }
                                </StyledSubmitButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isUpdateUserRouteSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    // background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

const paperStyles = {
    width: '80%',
    // height: '100%',
    // margin: '32px',
    padding: '32px'
}

const formStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
}