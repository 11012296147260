import React from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import _throttle from 'lodash/throttle'
import { MAP_CONFIG } from '../../../App.config'
// import isArrayLikeObject from 'lodash/isArrayLikeObject'
// import findIndex from 'lodash/findIndex'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import StyledCard from '../../common/StyledCard'
import StyledCardVersion2 from '../../common/StyledCardVersion2'
import StyledDateTimePicker from '../../common/StyledDateTimePicker'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledDataGrid from '../../common/StyledDataGrid'
// import StyledDataGrid2 from '../../common/StyledDataGrid2'
// import UserTripTimeline from './UserTripTimeline'
import NativeSelectMenu from '../../common/NativeSelectMenu'
import UserDetails from './UserDetails'
import KeplerGl from '../../KeplerGl/KeplerGl'
import ErrorBoundary from '../../common/ErrorBoundary'

// Import Actions & Methods
import { loadInitialCustomMap, clearMap } from '../../../store/actions/mapActions'
import { getServiqAnalytics, getAllGroups, deactivateTraceUserSocket, traceUser, getUserTripTimelineWithinTimeRange, dispatchUserTripTimelineDataToMap, getUsersByGroupId, getAreaByUserId, getUserAnalytics, getUserRoutePointsWithinTimeRangeImproved } from '../../../store/actions/companyDashboardActions'
import { setLayerSizeWithZoomLevel } from '../../../store/actions/layerActions'
import { getRoutePointTableData, } from '../../../utils/utils'
import { updateMap } from 'kepler.gl/actions'
import UserRoutePoints from './UserRoutePoints'

class AnalyticsForServiq extends React.PureComponent {
    state = {
        totalUsers: '-',
        startTime: new Date(),
        endTime: new Date(),
        groupList: [],
        selectedGroup: -1,
        mapWidth: 300,
        mapHeight: 360,
        selectedGroupUsers: [],
        selectedMapLayer: 'timeline',
        selectedUser: {},
        timelineData: [],
        selectedGroupTotalUsers: '-',
        selectedGroupTotalWorkingDays: '-',
        selectedGroupTotalWorkingHours: '-',
        selectedGroupTotalDistance: '-',
        isUserDetailsDialogOpen: false,
        isSnackbarOpen: false,
        isGetAnalyticsSuccessful: false,
        feedbackMessage: '',
        loading: false,

        routePoints: [],
        totalStallPoints: '-',
        temp: [],
        tripCountInTimeRange: '-',
        pingCountInTimeRange: '-',
        userList: [],
        selectedUserTest: -1,
        routeTableData: [],

        selectedArea: -1,
        areaList: [],

        selectedUserTotalTrips: '-',
        selectedUserTotalPings: '-',
        selectedUserTotalWorkingDays: '-',
        selectedUserTotalDistance: '-',

        isRouteTableDataLoading: false,
        totalAddedShop: '-'
    }

    componentDidMount() {
        // Set Loading & tripsTodayActionLink
        this.setState({ loading: true })

        // Update Map Size
        this._updateMapSize()

        // Add Window Resize Event
        this.onResize = _throttle(this._updateMapSize, 0, { trailing: true })
        window.addEventListener('resize', this.onResize)

        // Load Initial Custom Map Without Data
        this.props.dispatch( loadInitialCustomMap() )

        // Get Group List
        getAllGroups()
            .then(groups => {
                const groupList = groups.map(g => ({ ...g, value: g.id, label: g.group_name }))

                this.setState({ groupList })

            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching groups.', loading: false })
            })

        // Get Initial Analytics
        getServiqAnalytics()
            .then(res => {
                this.setState({
                    totalUsers: res.total_users,
                    loading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching data.', loading: false })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const { zoom } = this.props
        const { selectedMapLayer, selectedUser } = this.state

        // Set Point Layer Radius with Zoom Level Change
        if(prevProps.zoom !== zoom) {
            this.props.dispatch( setLayerSizeWithZoomLevel(zoom) )
        }

        // If selectedUser Id changes in state
        if(prevState.selectedUser.user_id !== selectedUser.user_id) {
            // Toggle Map Layer
            this._toggleMapLayer(selectedMapLayer, selectedUser.user_id)
        }

        // If selectedMapLayer changes in state
        if(prevState.selectedMapLayer !== selectedMapLayer) {
            // Toggle Map Layer
            this._toggleMapLayer(selectedMapLayer, selectedUser.user_id)
        }
    }

    componentWillUnmount() {
        // Deactivate Trace User Socket
        this.props.dispatch( deactivateTraceUserSocket() )
    }

    // Update Map Size
    _updateMapSize = () => {
        const el = document.getElementById('map-container')
        if(el) {
            this.setState({ mapWidth: el.clientWidth - 32, mapHeight: el.clientHeight - 32 })
        }
    }

    // Handle On Change
    _onChange = e => {        
        this.setState({ [e.target.name]: e.target.value })

        const { selectedUser, selectedMapLayer } = this.state
        if ( e.target.name && e.target.name === 'selectedGroup' ) {
            
            this.setState({ loading: true, selectedUserTest: -1 })          

            // Get Users
            getUsersByGroupId({ group_id: e.target.value })
                .then(res => {
                    if (res.status === 200) {

                        const localUserList = res.users && res.users[0] && res.users[0].local_users
                        const userList = localUserList.map(g => ({ ...g, value: g.id, label: g.name }))
                        
                        this.setState({
                            userList,
                            loading: false
                        })

                        // If selectedUser Valid, populate Timeline Data
                        if(selectedUser.user_id) {
                            // Toggle Map Layer
                            this._toggleMapLayer(selectedMapLayer, selectedUser.user_id)
                        }

                    } else {
                        this.setState({
                            isSnackbarOpen: true,
                            isGetAnalyticsSuccessful: false,
                            feedbackMessage: 'Something went wrong.',
                            loading: false,
                            userList: []
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        isSnackbarOpen: true,
                        isGetAnalyticsSuccessful: false,
                        feedbackMessage: err.response ? err.response.message : err.message,
                        loading: false,
                        userList: []
                    })
                })
        }
        if (e.target.name && e.target.name === 'selectedUserTest') {
            
            this.setState({ loading: true, selectedArea: -1 })

            // Get Area
            getAreaByUserId({ user_id: e.target.value })
            .then(res => {
                
                if (res.status === 200) {
                    const resAreaList = res.data.areas
                    
                    const areaList = resAreaList.map(a => ({ ...a, value: a.area ? a.area : 'NULL', label: a.area ? a.area : 'N/A' }))
                    this.setState({ areaList, loading: false })               
                } else {
                    this.setState({
                        isSnackbarOpen: true,
                        isGetAnalyticsSuccessful: false,
                        feedbackMessage: 'Something went wrong.',
                        loading: false,
                        areaList: []
                    })
                }
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isGetAnalyticsSuccessful: false,
                    feedbackMessage: err.response ? err.response.message : err.message,
                    loading: false,
                    areaList: []
                })
            })
        }
    }

    // On Start Date-Time Input Change
    _onStartDateTimeChange = startTime => {
        this.setState({ startTime })
    }

    // On End Date-Time Input Change
    _onEndDateTimeChange = endTime => {
        this.setState({ endTime })
    }

    // On Form Submit
    _onSubmit = e => {
        e.preventDefault()

        const { selectedGroup, startTime, endTime, selectedMapLayer, selectedUserTest, selectedArea } = this.state

        if(selectedGroup && startTime && endTime && selectedUserTest && !!selectedArea && selectedArea !== -1) {
            // Set Loading
            this.setState({ loading: true, routeTableData: [], isRouteTableDataLoading: true })            
            
            // Get Analytics Withing Time Range
            getUserAnalytics({
                user_id: selectedUserTest,
                group_id: selectedGroup,                
                start_time: format(startTime, 'yyyy-MM-dd') + ' 00:00:00',
                end_time: format(endTime, 'yyyy-MM-dd') + ' 23:59:59'
            })
                .then(res => {                    
                    this.setState({
                        selectedUserTotalTrips: res.total_trip_count,
                        selectedUserTotalPings: res.total_ping_count,
                        selectedUserTotalWorkingDays: res.attendance_info.total,
                        selectedUserTotalDistance: res.total_distance,
                        loading: false
                    })
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        isSnackbarOpen: true,
                        isGetAnalyticsSuccessful: false,
                        feedbackMessage: err.response ? err.response.message : err.message,
                        loading: false
                    })
                })
            
            
            
            this._toggleMapLayer(selectedMapLayer, selectedUserTest, selectedArea)
        }
    }

    // Set Selected User
    _setSelectedUser = selectedRow => {
        this.setState({ selectedUser: selectedRow.row })
    }

    // On User Action Click
    _onUserActionClick = (selectedRow, event) => {
        event.stopPropagation()

        this.setState({
            selectedUser: selectedRow.row,
            isUserDetailsDialogOpen: true
        })
    }

    // Toggle Map Layer
    _toggleMapLayer = (selectedMapLayer, selectedUserId, selectedArea) => {
        const { startTime, endTime } = this.state

        if(selectedMapLayer === 'trace') {
            // Set Loading
            this.setState({ loading: true })

            // Deactivate Trace User Socket
            this.props.dispatch( deactivateTraceUserSocket() )

            // Clear Map
            this.props.dispatch( clearMap() )

            // Trace a user
            this.props.dispatch( traceUser(selectedUserId) )

            setTimeout(() => {
                this.setState({ loading: false })
            }, 500)

        } else if (selectedMapLayer === 'timeline') {            
            // Set Loading
            this.setState({ loading: true, timelineData: [], routePoints: [], totalStallPoints: '-' })

            // Deactivate Trace User Socket
            this.props.dispatch( deactivateTraceUserSocket() )

            // Clear Map
            this.props.dispatch( clearMap() )

            // Get user Trip Timeline Within Time Range
            getUserTripTimelineWithinTimeRange({
                user_id: selectedUserId,
                timeFrom: format(startTime, 'yyyy-MM-dd')  + ' 00:00:00',
                timeTo: format(endTime, 'yyyy-MM-dd') + ' 23:59:59'
            })
                .then(res => {
                    // this.props.dispatch( dispatchUserTripTimelineDataToMap(res.base_geo_json) )
                    const tData = res.base_geo_json
                    // Get User Route Points Within Time Range
                    this._getUserRoutePointsWithinTimeRange(selectedUserId, selectedArea, startTime, endTime, tData)  
                    // console.log('timeline_res', res)

                    // Get Timeline Data
                    let timelineData = []
                    let totalStallPoints = 0
                    if(res.base_geo_json.length > 0) {
                        timelineData = res.base_geo_json.map(f => {
                            if (f.features && f.features.length > 0) {
                                const stallPoints = f.features.filter(g => g.geometry.type === 'Point')
                                totalStallPoints = totalStallPoints + stallPoints.length
                            }
                            return f.features.filter(g => g.geometry.type === 'Point').map(f => ({
                                address: f.properties.address ? f.properties.address : '',
                                startTime: f.properties.start_time ?
                                    f.properties.start_time :
                                    '',
                                endTime: f.properties.end_time ?
                                    f.properties.end_time :
                                    '',
                                duration: f.properties.duration,
                                distance: f.properties.distance,
                                endPointType: f.properties.endpoint_type,
                                images: f.properties.images,
                                routePointId: f.properties.route_point_id,
                            }))
                            .sort((a, b) => {
                                if(!a.startTime || !b.startTime) {
                                    return 0
                                }
    
                                const startTimeA = new Date(a.startTime)
                                const startTimeB = new Date(b.startTime)
    
                                return startTimeA - startTimeB
                            })
                        }
                        )
                    }                    

                    this.setState({ loading: false, timelineData, totalStallPoints })
                })
                .catch(err => {
                    console.error(err)
                    this.setState({ loading: false })
                    this._getUserRoutePointsWithinTimeRange(selectedUserId, selectedArea, startTime, endTime, [])  
                })

            // // Get User Route Points Within Time Range
            // this._getUserRoutePointsWithinTimeRange(selectedUserId, startTime, endTime)            
        }
    }

    // Get User Route Points Within Time Range
    _getUserRoutePointsWithinTimeRange = (selectedUserId, selectedArea, startTime, endTime, tData) => {
        // const days = getDayList(startTime, endTime)
        // console.log('days', days)
        const dd = tData
        this.setState({ isRouteTableDataLoading: true })

        // Get geo fence / route point data within a time range
        getUserRoutePointsWithinTimeRangeImproved({
            user_id: selectedUserId,
            area: selectedArea,
            timeFrom: format(startTime, 'yyyy-MM-dd')  + ' 00:00:00',
            timeTo: format(endTime, 'yyyy-MM-dd') + ' 23:59:59'
        })
            .then(res => {

                // Config for MAP
                const config = {
                    DATA_ID: 'route_point_data',
                    DATA_LABEL: 'RoutePoints',
                    ENDPOINTS_DATA_ID: 'endpoints_data_RP',
                    ENDPOINTS_DATA_LABEL: 'Endpoints_RP',
                }

                
                // Reform Response Data for MAP
                const routePointsLocationData = 
                    {
                        type: 'FeatureCollection',
                        features: res.points.map(r => ({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [r.longitude, r.latitude]
                            },
                            properties: {
                                ...r,
                                endpoint_type: 'route_points',
                                shop_name: r.name ? r.name : 'N/A'
                            }
                        }))
                    }
                

                dd.push(routePointsLocationData)                
                
                // Add Route Points Data to the MAP
                this.props.dispatch(dispatchUserTripTimelineDataToMap(dd, config))
                
                // Get Group Info
                const { selectedGroup, groupList } = this.state
                const selectedGroupInfo = groupList.find(o => o.value === selectedGroup)

                // Create Route Point Table
                const routeTableData = getRoutePointTableData(res.points, selectedGroupInfo)
                                
                // Get Route Points
                let routePoints = []

                if (res && res.points && res.points.length > 0) {                                              
                    routePoints = res.points.map(g => ({
                        id: g.id,
                        address: g.address,
                        area: g.area,
                        images: g.images,
                        geofence: g.geofence,
                        username: g.user.name,
                        shopname: g.name,
                        routePointId: g.route_point_id
                    }))                                                   
                }
                
                const totalAddedShop = !!res && !!res.total ? res.total : '-'

                this.setState({ routePoints, routeTableData, loading: false, isRouteTableDataLoading: false, totalAddedShop })
            })
            .catch(err => {                
                this.setState({ loading: false, isRouteTableDataLoading: false })
                console.error(err)
            })
    }
    
    // Close User Details Dialog
    _closeUserDetailsDialog = () => {
        this.setState({ isUserDetailsDialogOpen: false })
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // Get Selected Shop Information
    _getShopInformation = shop => {
        const { routeTableData } = this.state
        const { dispatch } = this.props

        if (!!routeTableData && routeTableData.length > 0 && !!shop) {
            const  selectedShop = routeTableData.find( r => r.id === shop.id )
            if (!!selectedShop) {
                dispatch( updateMap({
                    longitude: selectedShop.longitude,
                    latitude: selectedShop.latitude,
                    zoom: 20
                }))
            }
        } 
    }

    render() {
        const { totalUsers, startTime, endTime, isSnackbarOpen, isGetAnalyticsSuccessful, feedbackMessage, loading, groupList, selectedGroup, mapWidth, mapHeight, selectedMapLayer, isUserDetailsDialogOpen, selectedUser, routePoints, userList, selectedUserTest, routeTableData, selectedUserTotalTrips, selectedUserTotalPings, selectedUserTotalWorkingDays, selectedUserTotalDistance, isRouteTableDataLoading, areaList, selectedArea, totalAddedShop } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }
                
                <Grid container={ true } spacing={ 2 }>
                    <Grid item={ true } xs={ 12 }>
                        <StyledCardVersion2
                            title={ 'Total Users' }
                            value={ totalUsers }
                            badgeColor='#4cbb17'
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 }>
                        <Paper elevation={ 4 } style={{ padding: '16px' }}>
                            <form onSubmit={ this._onSubmit } style={ formStyles }>
                                <Grid container={ true } spacing={ 2 }>
                                    <Grid item={ true } xs={ 12 } sm>
                                        <StyledSelectMenuVersion2
                                            menuItems={[ { value: -1, label: '--Select Group--' }, ...groupList ]}
                                            label='Select Group'
                                            name='selectedGroup'
                                            required={ true }
                                            value={ selectedGroup }
                                            onChange={ this._onChange }
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } sm>
                                        <StyledSelectMenuVersion2
                                            menuItems={[ { value: -1, label: '--Select User--' }, ...userList ]}
                                            label='Select User'
                                            name='selectedUserTest'
                                            required={ true }
                                            value={ selectedUserTest }
                                            onChange={ this._onChange }
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } sm>
                                        <StyledSelectMenuVersion2
                                            menuItems={[ { value: -1, label: '--Select Area--' }, ...areaList ]}
                                            label='Select Area'
                                            name='selectedArea'
                                            required={ true }
                                            value={ selectedArea }
                                            onChange={ this._onChange }
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } sm>
                                        <StyledDateTimePicker
                                            value={ startTime }
                                            onChange={ this._onStartDateTimeChange }
                                            inputProps={{ required: true }}
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } sm>
                                        <StyledDateTimePicker
                                            value={ endTime }
                                            onChange={ this._onEndDateTimeChange }
                                            inputProps={{ required: true }}
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } md>
                                        <StyledSubmitButton
                                            type='submit'
                                            fullWidth={ true }
                                            variant='contained'
                                            style={{ marginBottom: '0px', marginTop: '4px' }}
                                        >
                                            { 'Get Analytics' }
                                        </StyledSubmitButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>

                    <Grid item={ true } xs={ 12 }>
                        <Grid container={ true } spacing={ 1 }>
                            {/* <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                <StyledCard
                                    title={ 'Total Users' }
                                    value={ selectedGroupTotalUsers }
                                    isBadge={ false }
                                    badgeColor='#6a5acd'
                                    titleStyles={{
                                        fontSize: '1rem'
                                    }}
                                />
                            </Grid> */}

                            <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                <StyledCard
                                    title={ 'Total Trips' }
                                    value={ selectedUserTotalTrips }
                                    isBadge={ false }
                                    badgeColor='#6a5acd'
                                    titleStyles={{
                                        fontSize: '1rem'
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                <StyledCard
                                    title={ 'Total Pings' }
                                    value={ selectedUserTotalPings }
                                    isBadge={ false }
                                    badgeColor='#6a5acd'
                                    titleStyles={{
                                        fontSize: '1rem'
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                <StyledCard
                                    title={ 'Total Working Days' }
                                    value={ selectedUserTotalWorkingDays }
                                    isBadge={ false }
                                    badgeColor='#6a5acd'
                                    titleStyles={{
                                        fontSize: '1rem'
                                    }}
                                />
                            </Grid>

                            {/* <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                <StyledCard
                                    title={ 'Total Working Hours' }
                                    value={ selectedGroupTotalWorkingHours }
                                    isBadge={ false }
                                    badgeColor='#6a5acd'
                                    titleStyles={{
                                        fontSize: '1rem'
                                    }}
                                />
                            </Grid> */}

                            <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                <StyledCard
                                    title={ 'Total Distance' }
                                    value={ selectedUserTotalDistance }
                                    isBadge={ false }
                                    badgeColor='#6a5acd'
                                    titleStyles={{
                                        fontSize: '1rem'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item={ true } xs={ 12 }>
                        <Grid container={ true } spacing={ 1 }>
                            {/* <Grid item={ true } xs={ 12 }>
                                <Paper elevation={ 4 } style={{ padding: '16px' }}>
                                    <StyledDataGrid2
                                        title={ `Users (${ selectedGroup !== -1 ? groupList.find(g => g.value === selectedGroup).label : 'None' })` }
                                        columns={[
                                            // { field: 'id', headerName: 'ID', flex: 1 },
                                            { field: 'routePointId', headerName: 'Address', flex: 1 },
                                            // { field: 'properties.name', headerName: 'Group Name', flex: 1 },
                                            // { field: 'address', headerName: 'Address', flex: 1 },
                                            // { field: 'duration', headerName: 'Duration', flex: 1 },
                                            // { field: 'routePointId', headerName: 'Tags', flex: 1 }
                                        ]}
                                        rows={ routePoints }
                                        onRowClick={ this._setSelectedUser }
                                        onActionClick={ this._onUserActionClick }
                                        toolbar={ true }
                                    />
                                </Paper>
                            </Grid> */}

                            <Grid item={ true } xs={ 12 }>
                                <Paper elevation={ 4 } style={{ padding: '16px', }}>
                                    <StyledDataGrid
                                        title={ `Users (${ selectedGroup !== -1 ? groupList.find(g => g.value === selectedGroup).label : 'None' })` }
                                        columns={[
                                            { field: 'username', headerName: 'Name', flex: 1 },
                                            { field: 'group', headerName: 'Group', flex: 1 },
                                            { field: 'address', headerName: 'Address', flex: 1.5 },
                                            { field: 'shopname', headerName: 'Shop name', flex: 1.5 },
                                            { field: 'date', headerName: 'Date', flex: 1 },
                                            { field: 'in', headerName: 'In time', flex: 1 },
                                            { field: 'out', headerName: 'Out time', flex: 1 },
                                            { field: 'duration', headerName: 'Duration', flex: 1 }
                                        ]}
                                        rows={ routeTableData }
                                        toolbar={ true }
                                        loading={ isRouteTableDataLoading }
                                        getShopInformation={ this._getShopInformation }
                                    />
                                </Paper>
                            </Grid>

                            {/* <Grid item={ true } xs={ 12 }>
                                <Paper elevation={ 4 } style={{ padding: '16px' }}>
                                    <StyledDataGrid
                                        title={ `Users (${ selectedGroup !== -1 ? groupList.find(g => g.value === selectedGroup).label : 'None' })` }
                                        columns={[
                                            { field: 'name', headerName: 'Name', flex: 1 },
                                            { field: 'total_working_days', headerName: 'Total Working Days', flex: 1 },
                                            { field: 'total_absent_days', headerName: 'Total Absent Days', flex: 1 }
                                        ]}
                                        rows={ selectedGroupUsers }
                                        onRowClick={ this._setSelectedUser }
                                        onActionClick={ this._onUserActionClick }
                                        toolbar={ true }
                                    />
                                </Paper>
                            </Grid> */}

                            {/* Map & Timeline */}

                            <Grid item={ true } xs={ 12 } sm={ 12 } md={ 8 }>
                                <Paper elevation={ 4 } style={{ width: '100%' }}>
                                    <Paper id='map-container' variant='outlined' style={ mapContainerStyles }>
                                        <ErrorBoundary>
                                            <KeplerGl
                                                id='map'
                                                mapboxApiAccessToken={ MAP_CONFIG.MAPBOX_TOKEN }
                                                width={ mapWidth }
                                                height={ mapHeight }
                                                mint={ true }
                                            />

                                            <NativeSelectMenu
                                                name='selectedMapLayer'
                                                options={[
                                                    { value: 'trace', label: 'Trace (Live)' },
                                                    { value: 'timeline', label: 'Timeline' }
                                                ]}
                                                value={ selectedMapLayer }
                                                onChange={ this._onChange }
                                                style={{
                                                    position: 'absolute',
                                                    top: '24px',
                                                    left: '24px',
                                                    background: '#f5f5f5'
                                                }}
                                            />
                                        </ErrorBoundary>
                                    </Paper>
                                </Paper>
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 } md={ 4 }>
                                <Grid container={ true } spacing={ 1 }>
                                    <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                        {/* <StyledCard
                                            title={ 'Total Stall Points' }
                                            value={ totalStallPoints }
                                            isBadge={ false }
                                            badgeColor='#6a5acd'
                                            titleStyles={{
                                                fontSize: '1rem'
                                            }}
                                        /> */}
                                        <StyledCard
                                            title={ 'Total Added Shops' }
                                            value={ totalAddedShop }
                                            isBadge={ false }
                                            badgeColor='#6a5acd'
                                            titleStyles={{
                                                fontSize: '1rem'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={ true } xs={ 12 } sm={ 12 } md={ true }>
                                        <StyledCard
                                            title={ 'Selected Area Shops' }
                                            value={ routePoints && routePoints.length > 0 ? routePoints.length : '-' }
                                            isBadge={ false }
                                            badgeColor='#6a5acd'
                                            titleStyles={{
                                                fontSize: '1rem'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} md={12}>
                                        <Paper elevation={ 4 } style={{ width: '100%', height: `${ mapHeight - 50 }px`, }}>
                                            {/* <UserTripTimeline
                                                data={ timelineData }
                                            /> */}
                                            <UserRoutePoints
                                                routePoints={ routePoints }
                                                loading={ isRouteTableDataLoading }
                                            />                                            
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <UserDetails
                    open={ isUserDetailsDialogOpen }
                    onClose={ this._closeUserDetailsDialog }
                    userDetails={ selectedUser }
                />

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isGetAnalyticsSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

const formStyles = {
    width: '100%'
}

const mapContainerStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapStateToProps = state => ({
    zoom: state.keplerGl.map ? state.keplerGl.map.mapState.zoom : 0
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsForServiq)