import React from 'react'
import { connect } from 'react-redux'
import _throttle from 'lodash/throttle'
import { MAP_CONFIG } from '../../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'
import KeplerGl from '../../KeplerGl/KeplerGl'
import ErrorBoundary from '../../common/ErrorBoundary'

// Import Actions & Methods
import { loadInitialCustomMap } from '../../../store/actions/mapActions'
import { setLayerSizeWithZoomLevel } from '../../../store/actions/layerActions'
import { getAllUsers, traceUser, deactivateTraceUserSocket } from '../../../store/actions/companyDashboardActions'

class TraceUser extends React.PureComponent {
    state = {
        mapWidth: 400,
        mapHeight: 400,
        userList: [],
        selectedUser: -1,
        isSnackbarOpen: false,
        isTraceUserSuccessful: false,
        feedbackMessage: '',
        loading: false
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        // Update Map Size
        this._updateMapSize()

        // Add Window Resize Event
        this.onResize = _throttle(this._updateMapSize, 0, { trailing: true })
        window.addEventListener('resize', this.onResize)

        // Load Initial Custom Map Without Data
        this.props.dispatch( loadInitialCustomMap() )

        // Get userList
        getAllUsers()
            .then(users => {
                const userList = users.map(u => ({ ...u, value: u.user_id, label: u.name }))
                this.setState({ userList, loading: false })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching users.', loading: false })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const { zoom } = this.props
        const { selectedUser } = this.state

        // Set Point Layer Radius with Zoom Level Change
        if(prevProps.zoom !== zoom) {
            this.props.dispatch( setLayerSizeWithZoomLevel(zoom) )
        }

        // If Selected User Changes, Trace that particular user
        if(prevState.selectedUser !== selectedUser) {
            // Set Loading
            this.setState({ loading: true })

            // Trace a user
            this.props.dispatch( traceUser(selectedUser) )

            setTimeout(() => {
                this.setState({ loading: false })
            }, 500)
        }
    }

    componentWillUnmount() {
        // Deactivate Trace User Socket
        this.props.dispatch( deactivateTraceUserSocket() )
    }

    // Update Map Size
    _updateMapSize = () => {
        const el = document.getElementById('map-container')
        this.setState({ mapWidth: el.clientWidth - 8, mapHeight: el.clientHeight - 8 })
    }

    // On Input Change
    _onChange = e => {
        this.setState({ [ e.target.name ]: e.target.value })
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    render() {
        const { mapWidth, mapHeight, userList, selectedUser, isSnackbarOpen, isTraceUserSuccessful, feedbackMessage, loading } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Paper id='map-container' variant='outlined' style={ paperLeftStyles }>
                        <ErrorBoundary>
                            <KeplerGl
                                id='map'
                                mapboxApiAccessToken={ MAP_CONFIG.MAPBOX_TOKEN }
                                width={ mapWidth }
                                height={mapHeight}
                                mint={ true }
                            />
                        </ErrorBoundary>
                    </Paper>

                    <Paper variant='outlined' style={ paperRightStyles }>
                        <Typography
                            component='h1'
                            variant='h4'
                            align='center'
                            display='block'
                            style={{
                                color: '#505050',
                                marginBottom: '2rem',
                                fontSize: '32px'
                            }}
                        >
                            { 'Trace User' }
                        </Typography>

                        <Box style={ formContainerStyles }>
                            <Grid container={ true } spacing={ 3 }>
                                <Grid item={ true } xs={ 12 } sm={ 12 }>
                                    <InputLabel required={ true }>{ 'Select User' }</InputLabel>
                                    <StyledSelectMenuVersion2
                                        menuItems={[ { value: -1, label: 'None' }, ...userList ]}
                                        label='Select User'
                                        name='selectedUser'
                                        required={ true }
                                        value={ selectedUser }
                                        onChange={ this._onChange }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isTraceUserSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '100%',
    height: '80vh',
    padding: '32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch'
}

const paperLeftStyles = {
    width: '70%',
    height: '100%',
    marginRight: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperRightStyles = {
    padding: '1rem',
    width: '30%',
    marginLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const formContainerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const mapStateToProps = state => ({
    zoom: state.keplerGl.map ? state.keplerGl.map.mapState.zoom : 0
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TraceUser)