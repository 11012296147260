import { format, parseISO } from "date-fns"
import { utils, writeFile } from 'xlsx'

// Validate email
export function validateEmail(email) {
    email = email.trim()
    const verdict = { success: false, message: '' }

    if(email) {
        if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            verdict.success = true
            verdict.message = ''

        } else {
            verdict.success = false
            verdict.message = 'Invalid email address.'
        }

    } else {
        verdict.success = false
        verdict.message = 'Required field.'
    }

    return verdict
}

// Validate Password
export function validatePassword(password) {
    const verdict = { success: false, message: '' }

    if(password) {
        if (password.length < 6) {
            verdict.success = false
            verdict.message = 'Password must be atleast 6 digit!'

        } else {
            verdict.success = true
            verdict.message = ''
        }

    } else {
        verdict.success = false
        verdict.message = 'Required field.'
    }

    return verdict
}

// Validate Phone Number
export function validatePhoneNumber(phoneNumber) {
    const regExp = /^\d+$/
    const found = phoneNumber.match(regExp)
    const verdict = {
        success: true,
        message: ''
    }

    if(!phoneNumber) {
        verdict.success = false
        verdict.message = 'Empty field.'

    } else if(phoneNumber.length !== 11) {
        verdict.success = false
        verdict.message = 'Must be 11 characters.'

    } else if(!found) {
        verdict.success = false
        verdict.message = 'Numbers only.'
    }

    return verdict
}

// Validate Tracking Days
export function validateTrackingDays(trackingDays) {
    const validTrackingDays = [ 'sat', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri' ]
    const verdict = { success: false, message: '' }

    if(trackingDays) {
        const days = trackingDays.split(',').map(d => d.trim().toLowerCase())
        let valid = true
        
        days.forEach(d => {
            if(!validTrackingDays.includes(d)) {
                valid = false
            }
        })

        if(valid) {
            verdict.success = true
            verdict.message = ''

        } else {
            verdict.success = false
            verdict.message = 'Must contain first 3 letters of days separated by ,.'
        }

    } else {
        verdict.success = false
        verdict.message = 'Required field.'
    }

    return verdict
}

// Download CSV data as file
export function downloadCsv(csvData, downloadFileName) {
    const downloadAnchor = document.createElement('a')
    downloadAnchor.setAttribute('style', 'display:none;')
    document.body.appendChild(downloadAnchor)
    const blob = new Blob([ '\ufeff' + csvData ], { type: 'text/csv' })
    const downloadUrl = URL.createObjectURL(blob)
    downloadAnchor.href = downloadUrl
    downloadAnchor.download = downloadFileName + '.csv'
    downloadAnchor.click()
    downloadAnchor.remove()
}


export function getRoutePointTableData(data, groupInfo) {
    
    if (data && data.length > 0) {
        const temp = []
        data.forEach((d, di) => {
            
            if(d.geofence && d.geofence.length > 0) {
                d.geofence.forEach((g, gi) => {
                    
                    const date = g.date
                    const geoData = JSON.parse(g.geofence)
                    
                if(geoData && geoData.length > 0) {
                    geoData.forEach((gd, gdi) => {
                        // format(parseISO(gd.in_time), 'dd-MMM-yyyy h:mm aa')
                        const tt = ({
                            in: gd.in_time ?  format(parseISO(gd.in_time), 'h:mm:ss aa') : 'Not visited',
                            out: gd.out_time ?  format(parseISO(gd.out_time), 'h:mm:ss aa') : 'Not visited',
                            duration: gd.duration ? convertDuration(gd.duration) : '-',
                            username: d.user.name,
                            shopname: d.name ? d.name : 'N/A',
                            address: d.address ? d.address : 'N/A',
                            group: groupInfo.group_name,
                            latitude: d.latitude,
                            longitude: d.longitude,
                            date,
                        })
                        temp.push(tt)
                    })                    
                } else {
                    const emptyInd = ({
                        in: 'Not visited',
                        out: 'Not visited',
                        duration: 'Not visited',
                        username: d.user,
                        shopname: d.name ? d.name : 'N/A',
                        address: d.address ? d.address : 'N/A',
                        group: groupInfo.group_name,
                        date,
                    })
                    temp.push(emptyInd)
                }
                })
            }
        })
        
        if(temp && temp.length > 0) {
            const hh = temp.map((d, i) => ({
                ...d,
                id: i + 1
            }))
            const sortedData = hh.sort((a, b) => {
                if(!a.date || !b.date) {
                    return 0
                }
                if (a.date < b.date) {
                    return -1
                }
                if (a.date > b.date) {
                    return 1
                }
                return 0
            })
            
            return sortedData
        } else return []  
    }
}

  // Convert Duration
  export function convertDuration(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
}

export const getDayList = (startDate, endDate) => {
    console.log({ startDate, endDate })
    // format(parseISO(gd.in_time), 'dd-MMM-yyyy h:mm aa')
    const getDaysArray = (s,e) => {
      for( var a=[], d=s; d<=e; d.setDate(d.getDate()+1) ){ 
          a.push({ rDate: format(d, 'dd-MMM-yyyy') })
      }
      return a
    }
    
    const totalDays = getDaysArray(new Date(startDate),new Date(endDate))
    // console.log('days', totalDays)
    // totalDays.map((v)=> v.date.toISOString().slice(0,10)).join('')
    
    return totalDays
}

// Export Stats Data in a XLSX Format
export function exportStatsAsXlsx(stats, fileName) {    
    
    if(!stats || !Object.keys(stats)?.length) {
        return
    }

    const worksheet = utils.json_to_sheet( stats )
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, fileName)
    writeFile(workbook, `${ fileName }.xlsx`, { bookType: 'xlsx' })
    
}

// Export Stats Data in a CSV Format
export function exportStatsAsCsv(stats, fileName) {
    
    if(!stats || !Object.keys(stats)?.length) {
    return
    }

    const worksheet = utils.json_to_sheet( stats )
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, fileName)
    writeFile(workbook, `${ fileName }.csv`, { bookType: 'csv' })    
}