import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import Typography from '@material-ui/core/Typography'
// import IconButton from '@material-ui/core/IconButton'
// import InfoIcon from '@material-ui/icons/Info'
// import Input from '@material-ui/core/Input'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import SearchIcon from '@material-ui/icons/Search'

class StyledDataGrid extends React.PureComponent {
  // state = {
  //   searchQuery: ''
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const { searchQuery } = this.state

  //   // If searchQuery Changes
  //   if(prevState.searchQuery !== searchQuery) {
  //     document.getElementById('user-search-input').focus()
  //   }
  // }

  // // On Input Change
  // _onChange = e => {
  //   this.setState({ [ e.target.name ]: e.target.value })
  // }

  render() {
    const { columns, rows, title, width, height, toolbar, loading, getShopInformation } = this.props
    // const { searchQuery } = this.state
    // console.log('grid 1', rows)
    return (
      <div style={{ ...containerStyles, width }}>
        <Typography
          variant='button'
          style={{
            paddingBottom: '16px',
            color: '#696969'
          }}
        >
          { title }
        </Typography>

        <div style={{ width: '100%', height }}>
          <StyledGrid
            columns={[
              ...columns,
              // {
              //   field: '',
              //   headerName: '',
              //   sortable: false,
              //   disableClickEventBubbling: true,
              //   renderCell: params => {
              //     return (
              //       <div style={{ cursor: 'pointer' }}>
              //         <IconButton
              //           onClick={ (e) => onActionClick(params, e) }
              //         >
              //           <InfoIcon />
              //         </IconButton>
              //       </div>
              //     )
              //   },
              //   flex: 1
              // }
            ]}
            rows={
              // searchQuery ?
              //   rows.filter(r => r.name.toLowerCase().includes(searchQuery))
              //   :
                rows
            }
            density='compact'
            disableColumnMenu={ true }
            disableColumnSelector={ true }
            hideFooter={ true }
            isCellEditable={ () => false }
            isRowSelectable={ () => false }
            onRowClick={ (params) => getShopInformation ? getShopInformation(params.row) : null }
            disableExtendRowFullWidth={true}
            loading={ loading }
            components={{
              Toolbar: () =>
                toolbar ?
                  <GridToolbarContainer>
                    <GridToolbarExport />
                    {/* <Input
                      id='user-search-input'
                      name='searchQuery'
                      type='search'
                      placeholder='Search User...'
                      margin='dense'
                      startAdornment={
                        <InputAdornment position='start'>
                          <SearchIcon style={{ color: '#808080' }}/>
                        </InputAdornment>
                      }
                      value={ searchQuery }
                      onChange={ this._onChange }
                      style={{
                        margin: '8px',
                        marginLeft: 'auto'
                      }}
                    /> */}
                  </GridToolbarContainer>
                  :
                  null
            }}
          />
        </div>
      </div>
    )
  }
}

// Styled Components
const StyledGrid = withStyles({
  columnHeader: {
    fontSize: '12px'
  },
  cell: {
    fontSize: '12px'
  }
})(DataGrid)

// JSS Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

// Prop Types
StyledDataGrid.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  title: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onRowClick: PropTypes.func,
  toolbar: PropTypes.bool,
  onActionClick: PropTypes.func
}

StyledDataGrid.defaultProps = {
  columns: [],
  rows: [],
  title: 'Table Name',
  width: '100%',
  height: '280px',
  onRowClick: () => null,
  toolbar: false,
  onActionClick: () => null
}

export default StyledDataGrid