import React from 'react'

// Import Components
import LinearProgress from '@material-ui/core/LinearProgress'

class StyledLinearProgress extends React.PureComponent {
    render() {
        return (
            <LinearProgress
                variant='indeterminate'
                style={{
                    width: '100vw',
                    height: '6px',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    zIndex: 100
                }}
                { ...this.props }
            />
        )
    }
}

export default StyledLinearProgress