import React from 'react'
import { format, } from 'date-fns'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import StyledCard from '../../common/StyledCard'
import StyledCardVersion2 from '../../common/StyledCardVersion2'
import StyledDateTimePicker from '../../common/StyledDateTimePicker'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import GroupWiseCountAccordion from './GroupWiseCountAccordion'
import StyledSnackbar from '../../common/StyledSnackbar'
// import StyledDataGrid from '../../common/StyledDataGrid'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { getAnalytics, getAnalyticsWithinTimeRange, } from '../../../store/actions/companyDashboardActions'

class Analytics extends React.PureComponent {
    state = {
        totalUsers: '-',
        tripCountToday: '-',
        tripCountThisMonth: '-',
        tripCountLastMonth: '-',
        activeTrips: '-',
        pingCountToday: '-',
        pingCountThisMonth: '-',
        pingCountLastMonth: '-',
        groupWiseTripCountsThisMonth: [],
        groupWisePingCountsThisMonth: [],
        startTime: new Date(),
        endTime: new Date(),
        tripCountInTimeRange: '',
        pingCountInTimeRange: '',
        groupWiseTripCountsInTimeRange: [],
        groupWisePingCountsInTimeRange: [],
        tripsTodayActionLink: '',
        tripsThisMonthActionLink: '',
        tripsActiveActionLink: '',
        tripsLastMonthActionLink: '',
        isSnackbarOpen: false,
        isGetAnalyticsSuccessful: false,
        feedbackMessage: '',
        loading: false,
        tripList: []
    }

    componentDidMount() {
        // Set Trips Action Links
        const tripsTodayActionLink = this._generateTripsTodayActionLink('today')
        const tripsThisMonthActionLink = this._generateTripsTodayActionLink('this-month')
        const tripsLastMonthActionLink = this._generateTripsTodayActionLink('last-month')
        const tripsActiveActionLink = this._generateTripsTodayActionLink('active-trips')

        // Set Loading, tripsTodayActionLink & tripsThisMonthActionLink
        this.setState({ loading: true, tripsTodayActionLink, tripsThisMonthActionLink, tripsLastMonthActionLink, tripsActiveActionLink })

        // Get Initial Analytics
        getAnalytics()
            .then(res => {
                this.setState({
                    totalUsers: res.total_users,
                    tripCountToday: res.todays_trip_count,
                    tripCountThisMonth: res.current_month_trip_count,
                    tripCountLastMonth: res.previous_month_trip_count,
                    activeTrips: res.active_trips,
                    pingCountToday: res.todays_ping_count,
                    pingCountThisMonth: res.current_month_ping_count,
                    pingCountLastMonth: res.previous_month_ping_count,
                    groupWiseTripCountsThisMonth: res.group_wise_current_month_count,
                    groupWisePingCountsThisMonth: res.group_wise_current_month_ping_count,                    
                    loading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching users.', loading: false })
            })
    }

    // On Start Date-Time Input Change
    _onStartDateTimeChange = startTime => {
        this.setState({ startTime })
    }

    // On End Date-Time Input Change
    _onEndDateTimeChange = endTime => {
        this.setState({ endTime })
    }

    // On Form Submit
    _onSubmit = e => {
        e.preventDefault()

        const { startTime, endTime } = this.state

        // Set Loading
        this.setState({ loading: true })

        // Get Analytics Withing Time Range
        getAnalyticsWithinTimeRange({
            startTime: format(startTime, 'yyyy-MM-dd') + ' 00:00:00',
            endTime: format(endTime, 'yyyy-MM-dd') + ' 23:59:59'
        })
            .then(res => {
                this.setState({
                    tripCountInTimeRange: res.total_trip_count,
                    pingCountInTimeRange: res.total_ping_count,
                    groupWiseTripCountsInTimeRange: res.group_wise_trip_count,
                    groupWisePingCountsInTimeRange: res.group_wise_ping_count,
                    loading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isGetAnalyticsSuccessful: false,
                    feedbackMessage: err.response ? err.response.message : err.message,
                    loading: false
                })
            })
        
        // // Get API_KEY from Token
        // getApiKeyFromToken()
        //     .then(apiKey => {
        //         // Get User Trips within Time Range
        //         getUserTripsWithinTimeRange({
        //             user_id: 'ALL',
        //             start_time: format(startTime, 'yyyy-MM-dd')  + ' 00:00:00',
        //             end_time: format(endTime, 'yyyy-MM-dd') + ' 23:59:59',
        //             api_key: apiKey,
        //             unit: 'KM'
        //         })
        //             .then(res => {
        //                 // Error Message
        //                 if(!res.data && res.message) {
        //                     throw new Error(res.message)
        //                 }

        //                 //format(parseISO(g.in_time), 'dd-MMM-yyyy h:mm aa')
        //                 if (res.data) {
        //                     const tripList = res.data && res.data.trips.length > 0 ? res.data.trips.map(t => ({
        //                         id: t.trip_info.user_id,
        //                         name: t.trip_info.user_name,
        //                         tripId: t.trip_info.trip_id,
        //                         distance: t.trip_info.distance && t.trip_info.unit ? t.trip_info.distance + ' ' + t.trip_info.unit : '-',
        //                         startTime: t.trip_info.start_time ? format(parseISO(t.trip_info.start_time), 'dd-MMM-yyyy h:mm aa') : '-',
        //                         endTime: t.trip_info.end_time ? format(parseISO(t.trip_info.end_time), 'dd-MMM-yyyy h:mm aa') : '-',
        //                         tags: t.trip_info.tag ? t.trip_info.tag : '-'
        //                     })) : []
                            
        //                     setTimeout(() => {
        //                         this.setState({ tripList, loading: false })
        //                     })
        //                 }
                        
        //             })
        //             .catch(err => {
        //                 console.error(err)
        //                 this.setState({
        //                     isSnackbarOpen: true,
        //                     isGetUserTripsSuccessful: false,
        //                     feedbackMessage: err.message ? err.message : err,
        //                     loading: false
        //                 })
        //             })
        //     })
        //     .catch(err => {
        //         console.error(err)
        //         this.setState({
        //             isSnackbarOpen: true,
        //             isGetUserTripsSuccessful: false,
        //             feedbackMessage: err.message ? err.message : err,
        //             loading: false
        //         })
        //     })
    }

    // Generate Trips Today Action Link
    _generateTripsTodayActionLink = timeDifference => {
        const today = new Date()
        const endDay = new Date()
        
        
        if(timeDifference === 'today') {
            today.setHours(0, 0, 0)

        } else if(timeDifference === 'this-month') {
            today.setDate(today.getDate() - today.getDate() + 1)
            today.setHours(0, 0, 0)
        } else if (timeDifference === 'last-month') {
            today.setDate(today.getDate() - today.getDate() + 1)            
            today.setHours(0, 0, 0)
            today.setMonth(today.getMonth() - 1)
            
            const lastDate = new Date((new Date(today.getFullYear(), today.getMonth() + 1, 1)) - 1)            

            endDay.setDate(lastDate.getDate())
            endDay.setMonth(lastDate.getMonth())
        } 
            //     start_date: format(startTime, 'yyyy-MM-dd') + ' 00:00:00',
            //     end_date: format(endTime, 'yyyy-MM-dd') + ' 23:59:59'
        const startTime = format(today, 'yyyy-MM-dd') + ' 00:00:00'
        const endTime = format(endDay, 'yyyy-MM-dd') + ' 23:59:59'

        const tripsTodayActionLink =  timeDifference !== 'active-trips' ? `/user-trips?user_id=ALL&start_time=${startTime}&end_time=${endTime}` :  `/user-trips?user_id=ALL&active_trips=true`        
        
        return tripsTodayActionLink
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    render() {
        const { totalUsers, tripCountToday, tripCountThisMonth, tripCountLastMonth, activeTrips, pingCountToday, pingCountThisMonth, pingCountLastMonth, groupWiseTripCountsThisMonth, groupWisePingCountsThisMonth, startTime, endTime, tripCountInTimeRange, pingCountInTimeRange, groupWiseTripCountsInTimeRange, groupWisePingCountsInTimeRange, tripsTodayActionLink, tripsThisMonthActionLink, tripsLastMonthActionLink, tripsActiveActionLink, isSnackbarOpen, isGetAnalyticsSuccessful, feedbackMessage, loading, } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }
                
                <Grid container={ true } spacing={ 4 }>
                    <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 }>
                        <StyledCardVersion2
                            title={ 'Total Users' }
                            value={ totalUsers }
                            badgeColor='#4cbb17'
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 }>
                        <Paper elevation={ 4 } style={{ padding: '16px' }}>
                            <form onSubmit={ this._onSubmit } style={ formStyles }>
                                <Grid container={ true } spacing={ 4 }>
                                    <Grid item={ true } xs={ 12 } sm={ 4 }>
                                        <StyledDateTimePicker
                                            value={ startTime }
                                            onChange={ this._onStartDateTimeChange }
                                            inputProps={{ required: true }}
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } sm={ 4 }>
                                        <StyledDateTimePicker
                                            value={ endTime }
                                            onChange={ this._onEndDateTimeChange }
                                            inputProps={{ required: true }}
                                        />
                                    </Grid>

                                    <Grid item={ true } xs={ 12 } sm={ 4 }>
                                        <StyledSubmitButton
                                            type='submit'
                                            fullWidth={ true }
                                            variant='contained'
                                            style={{ marginBottom: '0px', marginTop: '4px' }}
                                        >
                                            { 'Get Analytics' }
                                        </StyledSubmitButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 3 }>
                        <StyledCard
                            title={ 'Trips (Today)' }
                            value={ tripCountToday }
                            badgeColor='#ff5733'
                            actionButton={ tripCountToday !== '-' }
                            actionLink={ tripsTodayActionLink }
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 3 }>
                        <StyledCard
                            title={ 'Trips (This Month)' }
                            value={ tripCountThisMonth }
                            badgeColor='#ff5733'
                            actionButton={ tripCountThisMonth !== '-' }
                            actionLink={ tripsThisMonthActionLink }
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 3 }>
                        <StyledCard
                            title={ 'Trips (Last Month)' }
                            value={ tripCountLastMonth }
                            badgeColor='#ff5733'
                            actionButton={ tripCountLastMonth !== '-' } 
                            actionLink={ tripsLastMonthActionLink }
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 3 }>
                        <StyledCard
                            title={ 'Active Trips' }
                            value={ activeTrips }
                            badgeColor='#ff5733'
                            actionButton={ activeTrips !== '-' } 
                            actionLink={ tripsActiveActionLink }
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 4 }>
                        <StyledCard
                            title={ 'Pings (Today)' }
                            value={ pingCountToday }
                            badgeColor='#6a5acd'
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 4 }>
                        <StyledCard
                            title={ 'Pings (This Month)' }
                            value={ pingCountThisMonth }
                            badgeColor='#6a5acd'
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 } md={ 4 }>
                        <StyledCard
                            title={ 'Pings (Last Month)' }
                            value={ pingCountLastMonth }
                            badgeColor='#6a5acd'
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 }>
                        <GroupWiseCountAccordion
                            id='group-trip-counts'
                            title='Group Trip Counts (This Month)'
                            list={ groupWiseTripCountsThisMonth }
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 } sm={ 6 }>
                        <GroupWiseCountAccordion
                            id='group-ping-counts'
                            title='Group Ping Counts (This Month)'
                            list={ groupWisePingCountsThisMonth }
                        />
                    </Grid>

                    <Grid item={ true } xs={ 12 }>
                        <Divider />
                    </Grid>                    

                    { tripCountInTimeRange !== '' &&
                        <Grid item={ true } xs={ 12 } sm={ 6 }>
                            <StyledCard
                                title={ 'Trips (In Time Range)' }
                                value={ tripCountInTimeRange }
                                badgeColor='#ff5733'
                            />
                        </Grid>
                    }

                    { pingCountInTimeRange !== '' &&
                        <Grid item={ true } xs={ 12 } sm={ 6 }>
                            <StyledCard
                                title={ 'Pings (In Time Range)' }
                                value={ pingCountInTimeRange }
                                badgeColor='#6a5acd'
                            />
                        </Grid>
                    }

                    { groupWiseTripCountsInTimeRange.length > 0 &&
                        <Grid item={ true } xs={ 12 } sm={ 6 }>
                            <GroupWiseCountAccordion
                                id='group-trip-counts-in-time-range'
                                title='Group Trip Counts (In Time Range)'
                                list={ groupWiseTripCountsInTimeRange }
                            />
                        </Grid>
                    }

                    { groupWisePingCountsInTimeRange.length > 0 &&
                        <Grid item={ true } xs={ 12 } sm={ 6 }>
                            <GroupWiseCountAccordion
                                id='group-ping-counts-in-time-range'
                                title='Group Ping Counts (In Time Range)'
                                list={ groupWisePingCountsInTimeRange }
                            />
                        </Grid>
                    }                
                </Grid>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isGetAnalyticsSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

const formStyles = {
    width: '100%'
}

export default Analytics