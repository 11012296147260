import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'

class StyledCardVersion2 extends React.PureComponent {
    render() {
        const { title, value, badgeColor } = this.props

        return(
            <Card
                elevation={ 4 }
                style={{
                    padding: '16px 0px',
                }}
            >
                <CardHeader
                    avatar={
                        <div
                            style={{
                                width: '16px',
                                height: '16px',
                                background: badgeColor,
                                borderRadius: '2px'
                            }}
                        />
                    }
                    action={
                        <div
                            style={{
                                marginRight: '32px',
                                color: '#2ddbac',
                                fontSize: '32px'
                            }}
                        >
                            { value }
                        </div>
                    }
                    title={ title }
                    titleTypographyProps={{
                        style: {
                            color: '#505050',
                            fontSize: '1.25rem'
                        }
                    }}
                    style={{
                        padding: '4px 16px'
                    }}
                />
            </Card>
        )
    }
}

// Prop Types
StyledCardVersion2.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    badgeColor: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ])
}

StyledCardVersion2.defaultProps = {
    title: '',
    value: '',
    badgeColor: '#4cbb17'
}

export default StyledCardVersion2