import React from 'react'
// import PropTypes from 'prop-types'
import { format } from 'date-fns'

// Import Components
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { convertDuration } from '../../utils/utils'
import { CircularProgress } from '@material-ui/core'

class GeofenceTimeline extends React.PureComponent {
  state = {
    selectedIndex: 0
  }

  // On Next
  _onNext = () => {
    const { data } = this.props
    let { selectedIndex } = this.state

    // Increment
    selectedIndex = selectedIndex + 1

    // Validate
    if(selectedIndex >= data.length) {
      return
    }

    this.setState({ selectedIndex })
  }

  // On Prev
  _onPrev = () => {
    let { selectedIndex } = this.state

    // Decrement
    selectedIndex = selectedIndex - 1

    // Validate
    if(selectedIndex < 0) {
      return
    }

    this.setState({ selectedIndex })
  }

  render() {
    const { data } = this.props
    const { selectedIndex } = this.state
    let date = ''
      if (data.length > 0) {
        date = data[selectedIndex].date
    }
    
    return (
      <Box style={containerStyles}>
        {
          !data ?
            <CircularProgress />
            :
            <>
              <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <IconButton
            fontSize='small'
            onClick={ this._onPrev }
            style={{
              padding: '0px 0px 4px 0px',
              color: selectedIndex === 0 ? '#c0c0c0' : '#2ddbac'
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant='button' color='textSecondary'>                
                { date ? date : 'N/A' }
          </Typography>

          <IconButton
            fontSize='small'
            onClick={ this._onNext }
            style={{
              padding: '0px 0px 4px 0px',
              color: selectedIndex >= data.length - 1 ? '#c0c0c0' : '#2ddbac'
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>

          <Paper variant='elevation' elevation={ 0 } style={ timelineContainerStyles }>
            {
              data.length > 0 && JSON.parse(data[ selectedIndex ].geofence).length > 0 ?
                <Box width='100%'>
                    {
                        JSON.parse(data[ selectedIndex ].geofence).map((d, i) => (
                            <Box key={ 'container' + i } display='flex' flexDirection='column' marginBottom='0.5rem'>
                                <Paper elevation={ 3 } style={ detailsPaperStyles }>
                                    <Typography variant={ 'caption' } > { `In time: ${format(new Date(d.in_time), 'hh:mm a')}` } </Typography>
                                    <Typography variant={ 'caption' } > { `Out time: ${format(new Date(d.out_time), 'hh:mm a')}` } </Typography>
                                    <Typography variant={ 'caption' } > { `Duration: ${d.duration ? convertDuration(d.duration) : 'N/A'}` } </Typography>
                                </Paper>                            
                            </Box>
                        ))
                    }
                </Box>            
                  :
                  <Box width='100%'>                
                      <Box display='flex' flexDirection='column' marginBottom='0.5rem'>
                          <Paper elevation={ 3 } style={ detailsPaperStyles }>
                            <Typography color='textSecondary'>{ 'Not visited' }</Typography>
                          </Paper>                            
                      </Box>                    
                </Box>               
              }
            </Paper>
            </>
        }  
      </Box>
    )
  }
}

// JSS Styles
const containerStyles = {
  width: '100%',
  // height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const timelineContainerStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start'
}

const detailsPaperStyles = {
  width: '100%',
  height: '100%',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

// // Prop Types
// GeofenceTimeline.propTypes = {
//   data: PropTypes.objectOf(PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number,
//       address: PropTypes.string,
//       area: PropTypes.string,
//       images: PropTypes.array,
//       geofence: PropTypes.array,
//       shopname: PropTypes.string,
//       username: PropTypes.string,
//       routePointId: PropTypes.string,
//     })
//   ))
// }

// GeofenceTimeline.defaultProps = {
//   data: []
// }

export default GeofenceTimeline