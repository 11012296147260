import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Import Components
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import StyledSubmitButton from '../common/StyledSubmitButton'

class StyledCard extends React.PureComponent {
    render() {
        const { title, value, badgeColor, actionButton, actionButtonText, actionLink, onActionClick, titleStyles, isBadge } = this.props

        return(
            <Card
                elevation={ 4 }
                style={{
                    padding: `${ isBadge ? '8px' : '4px' } 0px`,
                }}
            >
                <CardHeader
                    avatar={
                        isBadge ?
                        <div
                            style={{
                                width: '16px',
                                height: '16px',
                                background: badgeColor,
                                borderRadius: '2px'
                            }}
                        />
                        :
                        null
                    }
                    title={ title }
                    titleTypographyProps={{
                        style: {
                            color: '#505050',
                            fontSize: '1.25rem',
                            ...titleStyles
                        }
                    }}
                    style={{
                        padding: '4px 16px'
                    }}
                />

                <CardContent
                    style={{
                        padding: `4px ${ isBadge ? '48px' : '24px' }`,
                        color: '#2ddbac',
                        fontSize: isBadge ? '2rem' : '1.6rem'
                    }}
                >
                    { value }
                </CardContent>

                { actionButton &&
                    <CardActions>
                        <Link
                            to={ actionLink }
                            style={{
                                textDecoration: 'none',
                                marginLeft: 'auto',
                                marginRight: '8px'
                            }}
                        >
                            <StyledSubmitButton
                                size='small'
                                style={{
                                    margin: '0px',
                                    height: '28px'
                                }}
                                onClick={ onActionClick }
                            >
                                { actionButtonText ? actionButtonText : 'Read More...' }
                            </StyledSubmitButton>
                        </Link>
                    </CardActions>
                }
            </Card>
        )
    }
}

// Prop Types
StyledCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    isBadge: PropTypes.bool,
    badgeColor: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    actionButton: PropTypes.bool,
    actionButtonText: PropTypes.string,
    actionLink: PropTypes.string,
    onActionClick: PropTypes.func,
    titleStyles: PropTypes.object
}

StyledCard.defaultProps = {
    title: '',
    value: '',
    isBadge: true,
    badgeColor: '#4cbb17',
    actionButton: false,
    actionButtonText: 'Read More...',
    actionLink: '',
    onActionClick: () => null,
    titleStyles: {}
}

export default StyledCard