import React from 'react'
import { connect } from 'react-redux'
import _throttle from 'lodash/throttle'
import { MAP_CONFIG, TRACKER } from '../../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// import InputLabel from '@material-ui/core/InputLabel'
// import Divider from '@material-ui/core/Divider'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
// import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'
import StyledIconButton from '../../common/StyledIconButton'
import KeplerGl from '../../KeplerGl/KeplerGl'
import ErrorBoundary from '../../common/ErrorBoundary'

// Import Actions & Methods
import { loadInitialCustomMap } from '../../../store/actions/mapActions'
import { setLayerSizeWithZoomLevel } from '../../../store/actions/layerActions'
import { traceAllUsers, deactivateTraceUserSocket, deactivateTraceAllUsersSocket } from '../../../store/actions/companyDashboardActions'
import { updateMap } from 'kepler.gl/actions'

class TraceAllUsers extends React.PureComponent {
    state = {
        mapWidth: 400,
        mapHeight: 400,
        isSnackbarOpen: false,
        isTraceAllUsersSuccessful: false,
        feedbackMessage: '',
        loading: false,

        selectedUser: -1,
        selectedUserInfo: {},
        selectedIndex: -1,
        isListVisible: false,
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        // Update Map Size
        this._updateMapSize()

        // Add Window Resize Event
        this.onResize = _throttle(this._updateMapSize, 0, { trailing: true })
        window.addEventListener('resize', this.onResize)

        // Load Initial Custom Map Without Data
        this.props.dispatch( loadInitialCustomMap() )

        // Activate Trace All Users
        this.props.dispatch( traceAllUsers() )
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedUser } = this.state
        const { zoom, numOfUsers, usersOnline } = this.props

        // Set Point Layer Radius with Zoom Level Change
        if(prevProps.zoom !== zoom) {
            this.props.dispatch( setLayerSizeWithZoomLevel(zoom) )
        }

        // If Number of Users and usersOnline are changed
        if((prevProps.numOfUsers === '-' && numOfUsers !== '-') && (prevProps.usersOnline === '-' && usersOnline !== '-')) {
            this.setState({ loading: false })
        }

        // If Selected User Changes, Trace that particular user
        if (prevState.selectedUser !== selectedUser) {
            // Set Loading
            this.setState({ loading: true })

            // if (selectedUser === -1) {
            //     // Deactivate Trace User Socket
            //     this.props.dispatch( deactivateTraceUserSocket() )
                
            //     // Activate Trace All Users
            //     this.props.dispatch( traceAllUsers() )
            // } else {
                // Deactivate Trace All Users Socket
                // this.props.dispatch( deactivateTraceAllUsersSocket() )

                // Trace a user
                // this.props.dispatch( traceUser(selectedUser) )
            // }

            setTimeout(() => {
                this.setState({ loading: false })
            }, 500)
        }
    }

    componentWillUnmount() {
        // Deactivate Trace All Users Socket
        this.props.dispatch( deactivateTraceAllUsersSocket() )
        
        // Deactivate Trace User Socket
        this.props.dispatch( deactivateTraceUserSocket() )
    }

    // Update Map Size
    _updateMapSize = () => {
        const el = document.getElementById('map-container')
        if (el) {
            this.setState({ mapWidth: el.clientWidth - 8, mapHeight: el.clientHeight - 8 })   
        }
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // On Input Change
    _onChange = e => {
        const { onlineUserList, dispatch } = this.props
        const selectedUserInfo = onlineUserList ? onlineUserList.find(u => u.value === e.target.value) : {}
        if (selectedUserInfo) {
            dispatch( updateMap({
                longitude: selectedUserInfo.longitude,
                latitude: selectedUserInfo.latitude,
                zoom: 22
            }))   
        }
        this.setState({ [ e.target.name ]: e.target.value, selectedUserInfo })
    }

    // Handle List Items
    _handleListItemClick = (e, index, selectedUser) => {
        const { onlineUserList, dispatch } = this.props
        const selectedUserInfo = onlineUserList ? onlineUserList.find(u => u.value === selectedUser.value) : {}
        if (selectedUserInfo) {
            dispatch( updateMap({
                longitude: selectedUserInfo.longitude,
                latitude: selectedUserInfo.latitude,
                zoom: 21
            }))     
        }
        this.setState({ selectedIndex: index })
    }

    // Handle User List 
    _handleOnlineUserList = () => {
        const { isListVisible } = this.state
        this.setState({ isListVisible: !isListVisible })
    }

    render() {
        const { numOfUsers, usersOnline, onlineUserList } = this.props
        const { mapWidth, mapHeight, isSnackbarOpen, isTraceAllUsersSuccessful, feedbackMessage, loading, selectedIndex, isListVisible, } = this.state
        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Paper id='map-container' variant='outlined' style={ paperLeftStyles }>
                        <ErrorBoundary>
                            <KeplerGl
                                id='map'
                                mapboxApiAccessToken={ MAP_CONFIG.MAPBOX_TOKEN }
                                width={ mapWidth }
                                height={mapHeight}
                                mint={ true }
                            />
                        </ErrorBoundary>
                    </Paper>

                    <Paper variant='outlined' style={ paperRightStyles }>
                        <Typography
                            component='h1'
                            variant='h4'
                            align='center'
                            display='block'
                            style={{
                                color: '#505050',
                                marginBottom: '2rem',
                                fontSize: '32px'
                            }}
                        >
                            { 'Trace All' }
                        </Typography>

                        <Box style={ formContainerStyles }>
                            <Grid container={ true } spacing={ 3 }>
                                <Grid item={ true } xs={ 12 } style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ color: '#808080' }}>{ 'Total Users' }</Typography>
                                    <Typography style={{ color: '#2ddbac', fontSize: '24px' }}>{ numOfUsers }</Typography>
                                </Grid>

                                <Grid item={ true } xs={ 12 } style={{ display: 'flex', flexDirection: 'column' }} >
                                    
                                    <Typography style={{ color: '#808080' }}>{ 'Online' }</Typography>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography style={{ color: '#2ddbac', fontSize: '24px' }}>{ usersOnline }</Typography>
                                        <StyledIconButton onClick={ this._handleOnlineUserList }>
                                            <KeyboardArrowDownIcon />
                                        </StyledIconButton>
                                    </Box>
                                </Grid>

                                {
                                    usersOnline && usersOnline > 0 && isListVisible &&
                                    <Grid item={ true } xs={ 12 } sm={ 12 } style={{ maxHeight: '100%', overflow: 'auto' }}>
                                        <Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '3px', overflow: 'auto', maxHeight: '400px', minHeight: '300px', height: '300px' }}>
                                            <List>
                                                {
                                                    onlineUserList && onlineUserList.length > 0 &&
                                                    onlineUserList.map((user, index) => (
                                                        <ListItem
                                                            dense={ true }
                                                            button
                                                            selected={selectedIndex === (index + 1)}
                                                            onClick={ e => this._handleListItemClick(e, index + 1, user) }
                                                        >
                                                            <ListItemText primary={`${index + 1}. ${user.label}`} />
                                                        </ListItem>
                                                    ) )
                                                }
                                            </List>
                                        </Box>
                                    </Grid>
                                }

                                {/* <Grid item={ true } xs={ 12 } sm={ 12 }>
                                    <InputLabel required={ true }>{ 'Select User' }</InputLabel>
                                    <StyledSelectMenuVersion2
                                        menuItems={[ { value: -1, label: 'All', latitude: 0, longitude: 0 }, ...onlineUserList ]}
                                        label='Select User'
                                        name='selectedUser'
                                        key={{ onlineUserList }}
                                        required={ true }
                                        value={ selectedUser }
                                        onChange={ this._onChange }
                                    />
                                </Grid> */}
                            </Grid>
                        </Box>
                    </Paper>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isTraceAllUsersSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

const paperStyles = {
    width: '100%',
    height: '80vh',
    padding: '32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch'
}

const paperLeftStyles = {
    width: '70%',
    height: '100%',
    marginRight: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperRightStyles = {
    padding: '1rem',
    width: '30%',
    marginLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const formContainerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: '100%',
    overflow: 'hidden'
}

const mapStateToProps = state => ({
    zoom: state.keplerGl.map ? state.keplerGl.map.mapState.zoom : 0,
    numOfUsers: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].dataContainer._rows ?
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].dataContainer._rows.filter(i => i[2] && i[3]).length
        : '-',
    usersOnline: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].dataContainer._rows ?
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].dataContainer._rows.filter(i => i.includes('online')).length
        : '-',
    onlineUserList: state.keplerGl.map &&
    state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ] &&
    state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].dataContainer._rows &&
        state.keplerGl.map.visState.datasets[TRACKER.DATA_ID].dataContainer._rows.filter(i => i.includes('online')).length > 0 ?
        state.keplerGl.map.visState.datasets[TRACKER.DATA_ID].dataContainer._rows.filter(i => i.includes('online')).map(u => ({ ...u, value: u[0], label: u[1], latitude: u[2], longitude: u[3]}))
    : [],
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TraceAllUsers)