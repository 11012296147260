import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    isSidePanelOpen: true,
    selectedTab: 0
}

const sidePanelReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_IS_SIDE_PANEL_OPEN:
            return {
                ...state,
                isSidePanelOpen: action.payload.isSidePanelOpen
            }
        
        case ActionTypes.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload.selectedTab
            }
        
        default:
            return state
    }
}

export default sidePanelReducer