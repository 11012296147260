import React from 'react'

// Import Components
import { Box, Typography, Grid, InputLabel, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import StyledInput from '../components/common/StyledInput'
import StyledSubmitButton from '../components/common/StyledSubmitButton'
import StyledSnackbar from '../components/common/StyledSnackbar'

// Import Actions
import { resetPassword } from '../store/actions/authActions'
class ResetPassword extends React.PureComponent{
    state = {
        token: null,        
        newPassword: '',
        newConfirmPassword: '',
        error: {},        
        newPasswordVisibility: false,
        newConfirmPasswordVisibility: false,

        isSnackbarOpen: false,
        isChangePasswordSuccessful: false,
        feedbackMessage: '',
        loading: false,
    }

    componentDidMount = () => {        
        const { search } = window.location

        // Extract Query Params if exists
        const queryParams = this._parseQueryParams(search)
        if (Object.keys(queryParams).length > 0 && queryParams.token ) {              
            
            this.setState({
                token: queryParams.token
            })
        } else {
            // Clear localStorage
            localStorage.clear()

            // Redirect to login
            window.location.replace('/login')
        }
        
    }

    // Parse Query Params
    _parseQueryParams = query => {
        const queryString = query.slice(1, query.length)        
        const keyValueStrings = queryString.split('&')        
        const params = Object.fromEntries(keyValueStrings.map(k => k.split('=')))                  
        return params
    }

    // Handle Password Visibility
    _handlePasswordVisibility = ( passwordType ) => {        
        this.setState({ [passwordType]: !this.state[passwordType] })
    }

    // Handle On Change
    _onChange = e => {        
        this.setState({ [ e.target.name ]: e.target.value })        
    }

    // Handle Mousedown Password
    _handleMouseDownPassword = e => {
        e.preventDefault()
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    _onSubmit = () => {
        const { newPassword, newConfirmPassword, error, token } = this.state
        if( newPassword !== newConfirmPassword ) {
            this.setState({
                error: {
                    ...error,
                    newPassword: 'Password did not match.'
                }
            })
        } else {
            this.setState({ loading: true })
            // Reset passord
            resetPassword({ token, password: newPassword })
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        isSnackbarOpen: true,
                        isChangePasswordSuccessful: res.status === 200,
                        feedbackMessage: res.message,
                        error: {},
                        currentPassword: '',
                        newPassword: '',
                        newConfirmPassword: '',
                        loading: false,
                        isDialogOpen: false,                        
                    })
                    // Clear localStorage
                    localStorage.clear()

                    // Redirect to login
                    window.location.replace('/login')
                } else {                    
                    this.setState({
                        isSnackbarOpen: true,
                        isChangePasswordSuccessful: false,
                        feedbackMessage: 'Something went wrong.',
                        loading: false,
                        error: {
                            ...error,
                            newPassword: 'Something went wrong.',
                            password: 'Something went wrong.',
                        }
                    })
                }           
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isChangePasswordSuccessful: false,
                    feedbackMessage: err.message,
                    loading: false,
                    error: {
                        ...error,
                        newPassword: err.message,
                        password: err.message,
                    }
                })
            })                        
        }
    }
    render() {
        const { error, newPassword, newConfirmPassword, newPasswordVisibility, newConfirmPasswordVisibility, isSnackbarOpen, isChangePasswordSuccessful, feedbackMessage } = this.state
        return (
            <Box>                
                <Grid container={ true } spacing={ 2 }>
                    <Grid item={ true } xs={ 12 } sm={ 12 }>
                        <Typography variant='h6'>
                            { 'Reset Password' }
                        </Typography>
                    </Grid>
                    <Grid item={ true } xs={ 12 } sm={ 12 }>
                        <InputLabel>{ 'New Password' }</InputLabel>
                        <StyledInput
                            error={ error.newPassword ? true : false }
                            variant='outlined'
                            margin='none'
                            size='small'
                            fullWidth={ true }
                            name='newPassword'
                            type={ newPasswordVisibility ? 'text' : 'password' }
                            value={ newPassword }
                            onChange={ this._onChange }
                            helperText={ error.newPassword ? error.newPassword : null }
                            placeholder='Enter new password...'
                            inputProps={{
                                style: {
                                    color: '#505050',
                                    fontSize: '16px'
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton                                                    
                                            aria-label='toggle password visibility'
                                            onClick={ () => this._handlePasswordVisibility('newPasswordVisibility') }
                                            onMouseDown={this._handleMouseDownPassword}
                                        >
                                            { newPasswordVisibility ? <Visibility /> : <VisibilityOff /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid> 

                    <Grid item={ true } xs={ 12 } sm={ 12 }>
                        <InputLabel>{ 'Confirm New Password' }</InputLabel>
                        <StyledInput
                            error={ error.newPassword ? true : false }
                            variant='outlined'
                            margin='none'
                            size='small'
                            fullWidth={ true }
                            name='newConfirmPassword'
                            type={ newConfirmPasswordVisibility ? 'text' : 'password' }
                            value={ newConfirmPassword }
                            onChange={ this._onChange }
                            helperText={ error.newPassword ? error.newPassword : null }
                            placeholder='Enter new password...'
                            inputProps={{
                                style: {
                                    color: '#505050',
                                    fontSize: '16px'
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={ () => this._handlePasswordVisibility('newConfirmPasswordVisibility') }
                                            onMouseDown={this._handleMouseDownPassword}
                                        >
                                            { newConfirmPasswordVisibility ? <Visibility /> : <VisibilityOff /> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>    
                    
                    <Grid item={ true } xs={ 12 } sm={ 12 }>
                        <StyledSubmitButton                                    
                            fullWidth={ false }
                            variant='contained'
                            onClick={ this._onSubmit }
                        >
                            { 'Submit' }
                        </StyledSubmitButton>
                    </Grid>
                </Grid>
                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isChangePasswordSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

export default ResetPassword