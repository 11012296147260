import { KeplerGlSchema } from 'kepler.gl/schemas'
import { mapStyleChange, mapConfigChange, receiveMapConfig, layerTextLabelChange, removeDataset } from 'kepler.gl/actions'

// Import Map Configs
import initial_custom_map_config from '../../configs/initial_custom_map_config.json'

// Load Initial Custom Map with Two Default Styles such as Mapbox Satellite and Barikoi Map Styles
export function loadInitialCustomMap() {
    return dispatch => {
        const initialCustomMapConfig = initial_custom_map_config
        const parsedInitialCustomMapConfig = KeplerGlSchema.parseSavedConfig(initialCustomMapConfig)
        dispatch( receiveMapConfig(parsedInitialCustomMapConfig) )
    }
}

// Set Current Map Style i.e Sattelite, Barikoi One Map
export function setCurrentMapStyle(mapStyleType) {
    return (dispatch, getState) => {
        // Check if already selected
        const { styleType } = getState().keplerGl.map.mapStyle
        if(styleType === mapStyleType) {
            return
        }

        dispatch( mapStyleChange(mapStyleType) )

        // Set Map Visible Layers
        const { visibleLayerGroups } = getState().keplerGl.map.mapStyle
        Object.keys(visibleLayerGroups).forEach(key => {
            if(key !== '3d building') {
                visibleLayerGroups[key] = true
            }
        })

        dispatch( mapConfigChange({ visibleLayerGroups }) )

        // Set Text Label Colors of all Layers in contrast with Map theme
        const { layers } = getState().keplerGl.map.visState
        const lightColor = [ 255, 255, 255 ]
        const darkColor = [ 0, 0, 0 ]
        let textColor = [ 255, 255, 255 ]

        // Set Text Color based on Map Style
        if(mapStyleType === 'barikoi_one_map' || mapStyleType === 'barikoi_osm_liberty') {
            textColor = darkColor

        } else {
            textColor = lightColor
        }

        layers.forEach(layer => {
            layer.config.textLabel.forEach((label, index) => {
                dispatch( layerTextLabelChange(layer, index, 'color', textColor) )
            })
        })
    }
}

// Clear Map Data
export function clearMap() {
    return (dispatch, getState) => {
        const map = getState().keplerGl.map

        if(map) {
            const { datasets } = map.visState

            Object.keys(datasets).forEach(k => {
                dispatch( removeDataset(k) )
            })
        }
    }
}