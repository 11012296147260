import React from 'react'
import { connect } from 'react-redux'

// Import Components
import { Box, Grid, Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent, InputLabel, IconButton, InputAdornment, CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { handleLogout, changeUserPassword } from '../../../store/actions/authActions'
import StyledInput from '../../common/StyledInput'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'

class UserActivity extends React.PureComponent {
    state = {
        username: null,
        isDialogOpen: false,
        currentPassword: '',
        newPassword: '',
        newConfirmPassword: '',
        error: {},
        currentPasswordVisibility: false,
        newPasswordVisibility: false,
        newConfirmPasswordVisibility: false,

        isSnackbarOpen: false,
        isChangePasswordSuccessful: false,
        feedbackMessage: '',
        loading: false,
        anchorEl: null,
    }

    componentDidMount = () => {
        const username = localStorage.getItem('username')
        this.setState({ username })
    }

    // Handle App Logout
    _handleAppLogout = () => {
        this.props.dispatch( handleLogout() )
    }

    // Handle on profile click
    _handleChangePasswordClick = () => {        
        this.setState({ isDialogOpen: true, anchorEl: null })
    }

    // Handle Dialog Close
    _handleDialogClose = () => {    
        this.setState({ isDialogOpen: false })
    }

    // Handle Password Visibility
    _handlePasswordVisibility = ( passwordType ) => {        
        this.setState({ [passwordType]: !this.state[passwordType] })
    }

    // Handle Mousedown Password
    _handleMouseDownPassword = e => {
        e.preventDefault()
    }

    // Handle On Change
    _onChange = e => {        
        this.setState({ [ e.target.name ]: e.target.value })        
    }

    _handleMenu = e => {
        this.setState({ anchorEl: e.currentTarget })
    }

    _onSubmit = () => {
        const { currentPassword, newPassword, newConfirmPassword, error } = this.state
        if( newPassword !== newConfirmPassword ) {
            this.setState({
                error: {
                    ...error,
                    newPassword: 'Password did not match.'
                }
            })
        } else if ( newPassword === currentPassword ) {
            this.setState({
                error: {
                    ...error,
                    password: 'Current password and new password cannot be matched.',
                    newPassword: 'Current password and new password cannot be matched.'
                }
            })
        } else {
            this.setState({ loading: true })
            // Change passord
            changeUserPassword({ current_password: currentPassword, password: newPassword })
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        isSnackbarOpen: true,
                        isChangePasswordSuccessful: res.status === 200,
                        feedbackMessage: res.message,
                        error: {},
                        currentPassword: '',
                        newPassword: '',
                        newConfirmPassword: '',
                        loading: false,
                        isDialogOpen: false,           
                    })
                } else {                    
                    this.setState({
                        isSnackbarOpen: true,
                        isChangePasswordSuccessful: false,
                        feedbackMessage: 'Something went wrong.',
                        loading: false,
                        error: {
                            ...error,
                            newPassword: 'Something went wrong.',
                            password: 'Something went wrong.',
                        }
                    })
                }           
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isChangePasswordSuccessful: false,
                    feedbackMessage: err.message,
                    loading: false,
                    error: {
                        ...error,
                        newPassword: err.message,
                        password: err.message,
                    }
                })
            })                        
        }
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // Handle Menu Close
    _handleMenuClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        const { username, isDialogOpen, error, currentPassword, newPassword, newConfirmPassword, currentPasswordVisibility, newPasswordVisibility, newConfirmPasswordVisibility, isChangePasswordSuccessful, isSnackbarOpen, feedbackMessage, loading, anchorEl } = this.state
        return (
            <Box style={ containerStyles }>
                { loading &&
                    <CircularProgress />
                }
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={ this._handleMenu }>
                    {
                        !!username && typeof username === 'string' &&
                        <Box style={ firstCharStyles } >
                            { username.charAt(0) }
                        </Box>
                    }
                    <Typography variant={ 'body2' }>
                        { username }
                    </Typography>
                </Box>                
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={ this._handleAppLogout }
                    style={{ color: '#fff', textTransform: 'none' }}
                >
                    { 'Logout' }
                </Button>

                <StyledMenu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}                    
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}    
                    getContentAnchorEl={null}
                    open={anchorEl}
                    onClose={this._handleMenuClose}
                >                    
                    <StyledMenuItem onClick={this._handleChangePasswordClick}>Change Password</StyledMenuItem>
                </StyledMenu>
                <Dialog
                    open={ isDialogOpen }
                    onClose={ this._handleDialogClose }
                    aria-labelledby="alert-dialog-title"
                    fullWidth={true}
                    disableBackdropClick={true}
                    scroll={'paper'}
                >
                    <DialogTitle id="alert-dialog-title">
                        { `Change Password` }                        
                    </DialogTitle>
                    <DialogContent dividers={'paper'}>                        
                        <Grid container={ true } spacing={ 2 }>
                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel>{ 'Current Password' }</InputLabel>
                                <StyledInput
                                    error={ error.password ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='currentPassword'
                                    type={ currentPasswordVisibility ? 'text' : 'password' }
                                    value={ currentPassword }
                                    onChange={ this._onChange }
                                    helperText={ error.password ? error.password : null }
                                    placeholder='Enter current password...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={ () => this._handlePasswordVisibility('currentPasswordVisibility') }
                                                    onMouseDown={this._handleMouseDownPassword}
                                                >
                                                    { currentPasswordVisibility ? <Visibility /> : <VisibilityOff /> }
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>       
                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel>{ 'New Password' }</InputLabel>
                                <StyledInput
                                    error={ error.newPassword ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='newPassword'
                                    type={ newPasswordVisibility ? 'text' : 'password' }
                                    value={ newPassword }
                                    onChange={ this._onChange }
                                    helperText={ error.newPassword ? error.newPassword : null }
                                    placeholder='Enter new password...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton                                                    
                                                    aria-label='toggle password visibility'
                                                    onClick={ () => this._handlePasswordVisibility('newPasswordVisibility') }
                                                    onMouseDown={this._handleMouseDownPassword}
                                                >
                                                    { newPasswordVisibility ? <Visibility /> : <VisibilityOff /> }
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid> 
                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel>{ 'Confirm New Password' }</InputLabel>
                                <StyledInput
                                    error={ error.newPassword ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='newConfirmPassword'
                                    type={ newConfirmPasswordVisibility ? 'text' : 'password' }
                                    value={ newConfirmPassword }
                                    onChange={ this._onChange }
                                    helperText={ error.newPassword ? error.newPassword : null }
                                    placeholder='Enter new password...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={ () => this._handlePasswordVisibility('newConfirmPasswordVisibility') }
                                                    onMouseDown={this._handleMouseDownPassword}
                                                >
                                                    { newConfirmPasswordVisibility ? <Visibility /> : <VisibilityOff /> }
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>                             
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={ this._handleDialogClose } color="primary">
                            Cancel
                        </Button>                        
                        <StyledSubmitButton                                    
                            fullWidth={ false }
                            variant='contained'
                            onClick={ this._onSubmit }
                        >
                            { 'Submit' }
                        </StyledSubmitButton>
                    </DialogActions>
                </Dialog>
                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isChangePasswordSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    display: 'flex',
    padding: '8px',
    marginTop: 'auto',
    marginBottom: '8px',
    maxHeight: '44px',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#FFF',
}

const firstCharStyles = {
    borderRadius: '50%',
    background: '#7fffd4',
    padding: '0.5rem',    
    height: '34px',
    width: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
    color: '#2f4f4f',
    cursor: 'pointer'
}

const StyledMenu = withStyles({
    paper: {
        background: '#404754',
        marginTop: '-0.4rem',
        marginLeft: '-0.4rem'
    },
})(Menu)

const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: '#fff',
        fontSize: '0.8rem'
    },
  }))(MenuItem);

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(UserActivity)