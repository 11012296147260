// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'

class StyledDrawer extends React.PureComponent {
    render() {
        const { children, width, ...restProps } = this.props

        return (
            <Drawer
                { ...restProps }
            >
                <Box
                    maxWidth='340px'
                    minWidth='200px'
                    width={ width }
                    height='100%'
                    overflow='hidden'
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    alignItems='center'
                    border='2px solid rgba(56, 64, 78, 0.8)'
                    style={{ background: 'rgba(56, 64, 78, 0.8)' }}
                >
                    { children }
                </Box>
            </Drawer>
        )
    }
}

StyledDrawer.propTypes = {
    width: PropTypes.string
}

StyledDrawer.defaultProps = {
    width: '300px'
}

export default StyledDrawer