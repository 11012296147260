import axios from 'axios'
import { AUTH } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Handle Login Action
export function handleLogin({ email, password }) {
    return dispatch => {
        // Request Login Access
        axios.post(AUTH.TRACE_COMPANY_ADMIN_LOGIN_API, { email, password })
            .then(res => {
                // If Login Success
                if (res.data.success) {
                    console.log('res.data', res.data)
                    const token = res.data.data
                    const companyId = res.data.company.id
                    const companyName = res.data.company.name                    
                    const username = res.data.user.name
                    const userId = res.data.user.id
                    
                    // Save to localStorage
                    localStorage.setItem('userEmail', email)
                    localStorage.setItem('token', token)
                    localStorage.setItem('companyId', companyId)
                    localStorage.setItem('companyName', companyName)
                    localStorage.setItem('username', username)
                    localStorage.setItem('userId', userId)

                    // Redirect to Trace App
                    window.location.replace('/')
                }
            })
            .catch(err => {
                const errorMessage = err.response && err.response.data ? err.response.data.message : 'Error occurred.'
                dispatch( setAuthError(errorMessage) )
                console.error(errorMessage)
            })
    }
}

// Handle Sign Up
export function handleSignUp({ companyName, phone, email, password }) {
    const name = _capitaliseCompanyName(companyName)
    
    return dispatch => {
        axios.post(AUTH.COMPANY_SIGNUP, { name, phone, email, password })
        .then(res => {
            // If Sign Up Success
            if (res.data.success) {                
                // Redirect to Trace App
                window.location.replace('/login')
            }
        })
        .catch(err => {
            const errorMessage = err.response && err.response.data ? err.response.data.message : 'Error occurred.'
            dispatch( setAuthError(errorMessage) )
            console.error(errorMessage)
        })
    }
}

// Capitalise Company Name
const _capitaliseCompanyName = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
}

// Handle Logout
export function handleLogout() {
    return () => {
        // Clear localStorage
        localStorage.clear()

        // Redirect to login
        window.location.replace('/login')
    }
}

// Set Auth Error
export function setAuthError(message) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_AUTH_ERROR, payload: { message } })
    }
}

/////////////////////////
// Helper Functions //
////////////////////////

// Validate auth token
export function tokenValidation(token) {
    return axios.get(AUTH.AUTH_USER, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res => {
            if (res.status === 200) {
                return true
            }
            return false
        })
        .catch(err => {
            console.error(err)
            return false
        })
}

// Change User Password
export function changeUserPassword(params) {    
    const token = localStorage.getItem('token')
    
    return axios.post(AUTH.USER_CHANGE_PASSWORD, params, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Forgot Password by Email Address
export function forgotPassword(params) {    
    
    return axios.post(AUTH.FORGOT_PASSWORD, params)
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}

// Reset Password
export function resetPassword(params) {    
    
    return axios.post(AUTH.RESET_PASSWORD, params)
        .then(res => {
            return res.data
        })
        .catch(err => {
            throw err
        })
}