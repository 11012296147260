import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import GroupIcon from '@material-ui/icons/Group'
import StyledInput from '../../common/StyledInput'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { createGroup, getAllGroups } from '../../../store/actions/companyDashboardActions'

class CreateGroup extends React.PureComponent {
    state = {
        groupList: [],
        groupName: '',
        error: {},
        isSnackbarOpen: false,
        isAddGroupSuccessful: false,
        feedbackMessage: '',
        loading: false
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        // Get Available Groups
        getAllGroups()
            .then(groups => {
                this.setState({ groupList: groups, loading: false })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching groups.', loading: false })
            })
    }

    // On Change
    _onChange = e => {
        this.setState({ [ e.target.name ]: e.target.value })
    }

    // On Submit
    _onSubmit = e => {
        e.preventDefault()

        const { groupName, error } = this.state

        if(groupName) {
            // Set Loading
            this.setState({ loading: true })

            createGroup(groupName)
                .then(res => {
                    this.setState({
                        isSnackbarOpen: true,
                        isAddGroupSuccessful: res.status === 200,
                        feedbackMessage: res.message,
                    })

                    // Get Available Groups
                    getAllGroups()
                        .then(groups => {
                            this.setState({ groupList: groups, loading: false })
                        })
                        .catch(err => {
                            console.error(err)
                            this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching groups.', loading: false })
                        })
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        isSnackbarOpen: true,
                        isAddGroupSuccessful: false,
                        feedbackMessage: err.message,
                        loading: false
                    })
                })

            // Set Errors
            this.setState({ error: {} })

        } else {
            this.setState({
                error: {
                    ...error,
                    group: 'Empty field.'
                }
            })
        }
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    render() {
        const { groupList, groupName, isSnackbarOpen, isAddGroupSuccessful, feedbackMessage, loading } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Typography
                        component='h1'
                        variant='h4'
                        align='center'
                        display='block'
                        style={{
                            color: '#505050'
                        }}
                    >
                        { 'Create Group' }
                    </Typography>

                    <form onSubmit={ this._onSubmit } style={ formStyles }>
                        <StyledInput
                            variant='outlined'
                            margin='none'
                            size='small'
                            fullWidth={ true }
                            name='groupName'
                            type='text'
                            value={ groupName }
                            onChange={ this._onChange }
                            placeholder='Enter group name...'
                            inputProps={{
                                style: {
                                    color: '#505050',
                                    fontSize: '16px'
                                }
                            }}
                            required={ true }
                        />

                        <StyledSubmitButton
                            type='submit'
                            variant='contained'
                            style={{ marginBottom: '0px', marginLeft: '8px' }}
                        >
                            { 'Add' }
                        </StyledSubmitButton>
                    </form>

                    <Box width='100%' height='100%'>
                        <Typography component='h6' variant='h6' style={{ padding: '4px 0px' }}>{ 'Groups' }</Typography>
                        <Paper variant='outlined' style={ listContainerStyles }>
                            <List aria-label='groups' style={ listStyles }>
                                {
                                    groupList.map((g, i) =>
                                        <ListItem key={ i } button={ true }>
                                            <ListItemIcon>
                                                <GroupIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={ g.group_name } />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Paper>
                    </Box>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isAddGroupSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '50%',
    height: '90%',
    padding: '32px',
    overflow: 'hidden'
}

const formStyles = {
    padding: '1rem 0px 1rem 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const listContainerStyles = {
    width: '100%',
    maxHeight: '66%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
}

const listStyles = {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto'
}

export default CreateGroup