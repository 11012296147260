import React from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'

// Import Components
import Box from '@material-ui/core/Box'
import ImageGallery from 'react-image-gallery'

class StyledImages extends React.PureComponent {
    state = {
        imagesData: null
    }

    componentDidMount = () => {
        this._formatImages()
    }

    // Format Images
    _formatImages = () => {
        const { images } = this.props

        if (images && images.length > 0) {
            const imagesData = images.map(image => ({
                original: image.url,
                thumbnail: image.url
            }))

            this.setState({ imagesData })
        }
    }
    render() {
        const { imagesData } = this.state
        
        return (
            <Box width='100%'>
                {
                    imagesData &&
                    <ImageGallery items={ imagesData } originalWidth='300px' originalHeight='300px' />
                }
            </Box>
        )
    }
}

export default StyledImages