import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import { Typography, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import StyledSnackbar from '../common/StyledSnackbar'


// Import Actions
import { exportStatsAsCsv } from '../../utils/utils'

class StyledDataGrid2 extends React.PureComponent {

  state = {
    isSnackbarOpen: false,
    isSuccessful: false,
    feedbackMessage: '',    
  }

  // Export Files
  _exportFiles = () => {
    const { rows, fileName } = this.props
    if(!rows || !rows.length) {
      this.setState({ isSnackbarOpen: true, feedbackMessage: 'No data to export.', isSuccessful: false })
    }
    exportStatsAsCsv(this._transformData(rows), fileName)
  }

  // Transforms Data
  _transformData = (data) => {
    if(!data) return []
    return data.map(d => {
      return {
        "Name": d.name,
        "Trip ID": d.tripId,
        "Distance": d.distance,
        "Start Time": d.startTime,
        "End Time": d.endTime,
        "Tags": d.tags,
      }
    })
  }

  // Handle On Snackbar Close
  _onSnackbarClose = (event, reason) => {
    if(reason === 'clickaway') {
        return
    }

    this.setState({ isSnackbarOpen: false })
  }

  render() {
    const { isSnackbarOpen, isSuccessful, feedbackMessage } = this.state
    const { columns, rows, title, width, height, setSelectedTrip, loading } = this.props    
    
    return (
      <div style={{ ...containerStyles, width }}>
        <Typography
          variant='button'
          style={{
            paddingBottom: '16px',
            color: '#696969'
          }}
        >
          { title }
        </Typography>

        <div style={{ width: '100%', height }}>
          <StyledGrid
            columns={[
              ...columns,              
            ]}
            rows={ rows }
            density='compact'
            disableColumnMenu={ true }
            disableColumnSelector={ true }
            hideFooter={ true }
            isCellEditable={ () => false }
            isRowSelectable={ () => false }            
            disableExtendRowFullWidth={true}
            onRowClick={ (params) => setSelectedTrip(params.row.tripId) }
            loading={ loading }
            components={{
              Toolbar: () => 
                <div style={ exportWrapperStyles }>                  
                  <StyledButton 
                    variant='text'
                    color='primary'
                    size='small'
                    startIcon={<GetAppIcon />}
                    onClick={ () => this._exportFiles() }
                  >
                    { 'Export CSV' }
                  </StyledButton>
                </div>                
            }}
          />
        </div>

        <StyledSnackbar
            open={ isSnackbarOpen }
            isSuccessful={ isSuccessful }
            message={ feedbackMessage }
            onClose={ this._onSnackbarClose }
        />
      </div>
    )
  }
}

// Styled Components
const StyledGrid = withStyles({
  columnHeader: {
    fontSize: '12px'
  },
  cell: {
    fontSize: '12px'
  }
})(DataGrid)

const StyledButton = withStyles({
  root: {
    '&:hover': {        
        boxShadow: 'none'
    },
    '&:active': {
        boxShadow: 'none',        
        borderColor: 'none'
    },
    '&:focus': {
        boxShadow: 'none',
        border: 'none'
    }
  },
  label: {
      paddingTop: '4px',
      color: 'rgba(41, 194, 153, 1)',
      fontWeight: 600
  }
})(Button)

// JSS Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}

const exportWrapperStyles = {
  display: 'flex', 
  justifyContent: 'flex-end', 
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)', 
  padding: '0 1rem'
}

// Prop Types
StyledDataGrid2.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  title: PropTypes.string,
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onRowClick: PropTypes.func,
  toolbar: PropTypes.bool,
  onActionClick: PropTypes.func
}

StyledDataGrid2.defaultProps = {
  columns: [],
  rows: [],
  title: 'Table Name',
  width: '100%',
  height: '100%',
  onRowClick: () => null,
  toolbar: false,
  onActionClick: () => null
}

export default StyledDataGrid2