import { layerVisConfigChange } from 'kepler.gl/actions'

// // Configs
// import { ROUTE } from '../../App.config'

// // Set Point Layer Radius based on Zoom Level
// export function setLayerSizeWithZoomLevel(zoom) {
//     return (dispatch, getState) => {
//         // Dispatch Radius Change for each point layer
//         const radius = getRadiusWithZoom(zoom)
//         const map = getState().keplerGl.map

//         if(map) {
//             const iconLayers = map.visState.layers.filter(layer => getLayerType(layer) !== 'point')
//             iconLayers.forEach(iconLayer => {
//                 dispatch( layerVisConfigChange(iconLayer, { radius }) )
//             })

//             // Set Route Layer Thickness with Zoom
//             const routeLayers = getState().keplerGl.map.visState.layers.filter(l => l.config.dataId === ROUTE.DATA_ID)
//             if(routeLayers.length > 0) {
//                 const thickness = getThicknessWithZoom(zoom)

//                 routeLayers.forEach(l => {
//                     dispatch( layerVisConfigChange(l, { thickness }) )
//                 })
//             }
//         }
//     }
// }

// ///////////////
// // Utilities //
// ///////////////
// export function getLayerType(curLayer) {
//     // If Layer exists
//     if(curLayer) {
//         if(curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
//             // Point Type
//             return 'point'

//         } else if(curLayer.config.columns.geojson && curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && curLayer.visConfigSettings.wireframe) {
//             // Polygon Type
//             return 'geojson'

//         } else if(curLayer.visConfigSettings.colorAggregation && !curLayer.visConfigSettings.resolution) {
//             // Grid Type
//             return 'grid'

//         } else if(curLayer.visConfigSettings.colorAggregation && curLayer.visConfigSettings.resolution) {
//             // Hexbin Type
//             return 'hexagon'

//         } else if(curLayer.computeHeatmapConfiguration) {
//             // Heatmap Type
//             return 'heatmap'

//         } else if(curLayer.visConfigSettings.clusterRadius) {
//             // Cluster Type
//             return 'cluster'

//         } else if(curLayer.dataToFeature && curLayer.dataToTimeStamp && curLayer.config.visConfig.trailLength) {
//             // Trip Type
//             return 'trip'
//         }
//     }

//     return ''
// }

// // Get Radius with Zoom
// function getRadiusWithZoom(zoom) {
//     let radius = 30

//     if(zoom >= 14 && zoom < 15) {
//         radius = 20

//     } else if(zoom >= 15 && zoom < 16) {
//         radius = 12.0

//     } else if(zoom >= 16 && zoom < 17) {
//         radius = 7.0

//     } else if(zoom >= 17 && zoom < 18) {
//         radius = 4.0

//     } else if(zoom >= 18 && zoom < 19) {
//         radius = 2.0

//     } else if(zoom >= 19 && zoom < 20) {
//         radius = 1.0

//     } else if(zoom >= 20 && zoom < 22) {
//         radius = 0.5

//     } else if(zoom >= 22 && zoom < 23) {
//         radius = 0.3

//     } else if(zoom >= 23 && zoom < 24) {
//         radius = 0.2

//     } else if(zoom >= 24) {
//         radius = 0.1
//     }

//     return radius
// }

// // Get Thickness With Zoom
// function getThicknessWithZoom(zoom) {
//     let thickness = 2

//     if(zoom > 15 && zoom <= 16) {
//         thickness = 1

//     } else if(zoom > 16 && zoom <= 17) {
//         thickness = 0.5

//     } else if(zoom > 17 && zoom <= 18) {
//         thickness = 0.4

//     } else if(zoom > 18 && zoom <= 19) {
//         thickness = 0.3

//     } else if(zoom > 19 && zoom <= 20) {
//         thickness = 0.2

//     } else if(zoom > 20) {
//         thickness = 0.1
//     }

//     return thickness
// }



// NEW CODE BLOCK
// Set Layer Size with Zoom Level
export function setLayerSizeWithZoomLevel(zoom) {
    return (dispatch, getState) => {
      const { map } = getState().keplerGl
      if(map) {
        const { layers } = map.visState
        const radius = getRadiusWithZoom(zoom)
        const thickness = getThicknessWithZoom(zoom)
        layers.forEach(l => {
          dispatch( layerVisConfigChange(l, { radius, thickness }) )
        })
      }
    }
}

// Get Radius with Zoom
function getRadiusWithZoom(zoom) {
    let radius = 30

    if(zoom >= 14 && zoom < 15) {
        radius = 20

    } else if(zoom >= 15 && zoom < 16) {
        radius = 12.0

    } else if(zoom >= 16 && zoom < 17) {
        radius = 7.0

    } else if(zoom >= 17 && zoom < 18) {
        radius = 4.0

    } else if(zoom >= 18 && zoom < 19) {
        radius = 2.0

    } else if(zoom >= 19 && zoom < 20) {
        radius = 1.0

    } else if(zoom >= 20 && zoom < 22) {
        radius = 0.5

    } else if(zoom >= 22 && zoom < 23) {
        radius = 0.3

    } else if(zoom >= 23 && zoom < 24) {
        // NEW CODE BLO
        radius = 0.2

    } else if(zoom >= 24) {
        radius = 0.1
    }

    return radius
}

// Get Thickness With Zoom
function getThicknessWithZoom(zoom) {
    let thickness = 2

    if(zoom > 15 && zoom <= 16) {
        thickness = 1

    } else if(zoom > 16 && zoom <= 17) {
        thickness = 0.5

    } else if(zoom > 17 && zoom <= 18) {
        thickness = 0.4

    } else if(zoom > 18 && zoom <= 19) {
        thickness = 0.3

    } else if(zoom > 19 && zoom <= 20) {
        thickness = 0.2

    } else if(zoom > 20) {
        thickness = 0.1
    }

    return thickness
}