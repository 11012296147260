import React from 'react'
import PropTypes from 'prop-types'
import { isArray, isEmpty } from 'lodash'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

class StyledTab extends React.PureComponent {
    state = {
        value: 0
    }

    // Hanlde Tab Index Value
    _handleTabIndexValue = (e, value) => {
        this.setState({ value })
        console.log(value)
    }
    render() {
        const { tabIndex, labels, handleTabIndex } = this.props
        return (
            <Box>
                <CustomizedTabs
                    value={ tabIndex }
                    onChange={ handleTabIndex }
                    variant='fullWidth'
                    centered
                >
                    {
                        isArray(labels) && !isEmpty(labels) &&
                            labels.map(label => (
                                <CustomizedTab key={ label } label={ label } />
                            ))
                    }
                </CustomizedTabs>
            </Box>
        )
    }
}

const CustomizedTabs = withStyles({
    root: {
        color: '#000000'
    },
    indicator: {
        backgroundColor: 'rgba(41, 194, 153, 0.8)'
    }
})(Tabs)

const CustomizedTab = withStyles({
    root: {
        textTransform: 'capitalize',
        fontSize: '1rem'
    },
    selected: {
        color: '#000000',
        "&.MuiTab-textColorPrimary": {
            color: "#000000"
          }
    }
})(Tab)

// Prop Types
StyledTab.propTypes = {
    tabIndex: PropTypes.number,
    label: PropTypes.array,
    handleTabIndex: PropTypes.func
}

StyledTab.defaultProps = {
    tabIndex: 0,
    label: [],
    handleTabIndex: () => null
}

export default StyledTab