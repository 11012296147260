import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import MenuListItemWithSubMenu from './MenuListItemWithSubMenu'
import MenuListItem from './MenuListItem'
import AssessmentIcon from '@material-ui/icons/Assessment'
import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import GroupIcon from '@material-ui/icons/Group'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import SettingsIcon from '@material-ui/icons/Settings'
import TimelineIcon from '@material-ui/icons/Timeline'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'

class MenuBody extends React.PureComponent {
    state = {
        menuList: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                Icon: AssessmentIcon,
                subMenuList: []
            },
            {
                id: 'user',
                title: 'User',
                Icon: PersonIcon,
                subMenuList: [
                    {
                        id: 'add-user',
                        title: 'Add User',
                        Icon: PersonAddIcon
                    },
                    {
                        id: 'update-user',
                        title: 'Update User',
                        Icon: EditIcon
                    },
                    {
                        id: 'delete-user',
                        title: 'Delete User',
                        Icon: DeleteForeverIcon
                    }
                ]
            },
            {
                id: 'group',
                title: 'Group',
                Icon: GroupIcon,
                subMenuList: [
                    {
                        id: 'create-group',
                        title: 'Create Group',
                        Icon: GroupAddIcon
                    },
                    {
                        id: 'assign-user-to-group',
                        title: 'Assign User To Group',
                        Icon: PersonAddIcon
                    }
                ]
            },
            {
                id: 'settings',
                title: 'Settings',
                Icon: SettingsIcon,
                subMenuList: []
            },
            {
                id: 'user-trips',
                title: 'User Trips',
                Icon: TimelineIcon,
                subMenuList: []
            },
            {
                id: 'user-trips-test',
                title: 'User Trips (Test)',
                Icon: TimelineIcon,
                subMenuList: []
            },
            {
                id: 'trace',
                title: 'Trace',
                Icon: PersonPinIcon,
                subMenuList: [
                    {
                        id: 'trace-user',
                        title: 'Trace User',
                        Icon: PersonIcon
                    },
                    {
                        id: 'trace-group',
                        title: 'Trace Group',
                        Icon: GroupIcon
                    },
                    {
                        id: 'trace-all-users',
                        title: 'Trace All Users',
                        Icon: AllInclusiveIcon
                    }
                ]
            }
        ]
    }

    render() {
        const { selectedMenuItem } = this.props
        const { menuList } = this.state

        return (
            <Box style={ containerStyles }>
                <List>
                    {
                        menuList.map((m, i) =>
                            m.subMenuList.length > 0 ?
                                <MenuListItemWithSubMenu
                                    key={ i }
                                    title={ m.title }
                                    Icon={ m.Icon }
                                    selectedId={ selectedMenuItem }
                                    subMenuList={ m.subMenuList }
                                    onClick={ this.props.changeSelectedMenu }
                                />
                                :
                                <MenuListItem
                                    key={ i }
                                    id={ m.id }
                                    title={ m.title }
                                    Icon={ m.Icon }
                                    selected={ m.id === selectedMenuItem }
                                    onClick={ this.props.changeSelectedMenu }
                                />
                        )
                    }
                </List>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    marginBottom: '8px',
    width: '100%',
    height: '100%',
    overflow: 'auto'
}

// Prop Types
MenuBody.propTypes = {
    selectedMenuItem: PropTypes.string,
    changeSelectedMenu: PropTypes.func
}

MenuBody.defaultProps = {
    selectedMenuItem: '',
    changeSelectedMenu: () => null
}

export default MenuBody