// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Container from '@material-ui/core/Container'
import BarikoiLogo from '../../common/BarikoiLogo'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

class Header extends React.PureComponent {
    state = {
        barikoiLogoTheme: 'light',
        background: 'rgba(56, 64, 78, 0.8)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
        appHeaderColor: '#fff'
    }

    componentDidMount() {
        const { theme } = this.props
        this._setTheme(theme)
    }

    componentDidUpdate(prevProps) {
        const { theme } = this.props

        // If theme changes in props
        if (prevProps.theme !== theme) {
            this._setTheme(theme)
        }
    }

    // Set Theme based on Props
    _setTheme = theme => {
        let barikoiLogoTheme = ''
        let background = ''
        let borderBottom = ''
        let appHeaderColor = ''

        switch (theme) {
            case 'light':
                barikoiLogoTheme = 'dark'
                background = 'rgba(255, 255, 255, 0.8)'
                borderBottom = '1px solid rgba(56, 64, 78, 0.5)'
                appHeaderColor = 'rgba(56, 64, 78)'
                break
            case 'dark':
                barikoiLogoTheme = 'light'
                background = 'rgba(56, 64, 78, 0.8)'
                borderBottom = '1px solid rgba(255, 255, 255, 0.5)'
                appHeaderColor = '#fff'
                break
            default:
                barikoiLogoTheme = 'light'
                background = 'rgba(56, 64, 78, 0.8)'
                borderBottom = '1px solid rgba(255, 255, 255, 0.5)'
                appHeaderColor = '#fff'
        }

        this.setState({ barikoiLogoTheme, background, borderBottom, appHeaderColor })
    }

    render() {
        const { title, closeSidePanel } = this.props
        const { barikoiLogoTheme, background, borderBottom, appHeaderColor } = this.state

        return (
            <Container disableGutters={ true } style={{ ...headerContainerStyles, background, borderBottom, }}>
                <Box margin='0px' padding='0px'>
                    <Link href='/' underline='none' style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                        <BarikoiLogo size='small' theme={ barikoiLogoTheme } />
                        <Typography variant='h6' style={{ ...appHeaderStyles, color: appHeaderColor }}>{ title }</Typography>
                        <Typography variant='caption' style={{ color: appHeaderColor, fontSize: '10px' }}>{ 'beta' }</Typography>
                    </Link>
                </Box>
                <IconButton style={{ padding: '6px' }} onClick={ closeSidePanel }>
                    <ChevronLeftIcon
                        style={{ ...IconStyles }}
                    />
                </IconButton>
            </Container>
        )
    }
}

// JSS Styles
const headerContainerStyles = {
    width: '100%',
    margin: '0px',
    padding: '8px',
    maxHeight: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
}

const appHeaderStyles = {
    margin: '0px',
    padding: '4px',
    display: 'inline-block',
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '500',
    fontSize: '18px',
    position: 'relative'
}

const IconStyles = {
    color: '#f5f5f5',
    fontSize: '20px',
}

// Prop Types
Header.propTypes = {
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.string.isRequired
}

Header.defaultProps = {
    theme: 'dark',
    title: 'App'
}

export default Header