import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import PersonIcon from '@material-ui/icons/Person'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuListItem from './MenuListItem'

class MenuListItemWithSubMenu extends React.PureComponent {
    state = {
        open: false
    }

    // Toggle Open
    _toggleOpen = () => {
        const { open } = this.state
        this.setState({ open: !open })
    }

    render() {
        const { title, Icon, selectedId, subMenuList } = this.props
        const { open } = this.state

        return (
            <React.Fragment>
                <ListItem button={ true } onClick={ this._toggleOpen }>
                    <ListItemIcon>
                        <Icon
                            style={{
                                color: subMenuList.findIndex(s => s.id === selectedId) >= 0 ? '#f5f5f5' : '#c8c8c8'
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={ title }
                        primaryTypographyProps={{
                            style: {
                                color: subMenuList.findIndex(s => s.id === selectedId) >= 0 ? '#f5f5f5' : '#c8c8c8',
                                fontWeight: subMenuList.findIndex(s => s.id === selectedId) >= 0 ? '700' : '500'
                            }
                        }}
                    />
                    { open ?
                        <ExpandLessIcon
                            style={{
                                color: subMenuList.findIndex(s => s.id === selectedId) >= 0 ? '#f5f5f5' : '#c8c8c8'
                            }}
                        />
                        :
                        <ExpandMoreIcon
                            style={{
                                color: subMenuList.findIndex(s => s.id === selectedId) >= 0 ? '#f5f5f5' : '#c8c8c8'
                            }}
                        />
                    }
                </ListItem>
                <Collapse in={ open } timeout='auto' unmountOnExit={ true }>
                    <List component='div' disablePadding={ true }>
                        {
                            subMenuList.map((s, i) =>
                                <MenuListItem
                                    key={ i }
                                    id={ s.id }
                                    title={ s.title }
                                    Icon={ s.Icon }
                                    dense={ true }
                                    selected={ s.id === selectedId }
                                    style={{ paddingLeft: '32px' }}
                                    onClick={ this.props.onClick }
                                />
                            )
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }
}

// Prop Types
MenuListItemWithSubMenu.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.oneOfType([ PropTypes.element, PropTypes.node ]),
    selectedId: PropTypes.string,
    subMenuList: PropTypes.array,
    onClick: PropTypes.func
}

MenuListItemWithSubMenu.defaultProps = {
    title: 'List Item',
    Icon: PersonIcon,
    selectedId: '',
    subMenuList: [],
    onClick: () => null
}

export default MenuListItemWithSubMenu