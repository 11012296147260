import React from 'react'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Chip from "@material-ui/core/Chip"

class StyledMultipleChipSelect extends React.PureComponent {
    
    // Filter Group Name From Selected Value
    _handleGroupName = (menuItems, selected) => {
        const selectedLabel = menuItems.filter(item => item.value === selected)[0].label
        return selectedLabel
    }
    render() {
        const { menuItems, value, onChange, required, name, multiple, selectProps, ...restProps } = this.props
        return (
            <FormControl
                variant='outlined'
                fullWidth={ true }
                margin='dense'
                size='small'
                required={ required }
                style={{ marginTop: '4px' }}
                { ...restProps }
            >
                <StyledSelect
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        getContentAnchorEl: null,
                        style: { maxHeight: '320px' }
                    }}
                    input={ <StyledOutlinedInput notched={ true } /> }
                    inputProps={{ name, required }}
                    multiple={ true }
                    value={ value }
                    onChange={onChange}
                    onMouseDown={(event) => {
                        event.stopPropagation();
                    }}
                    renderValue={(selected) => (
                        <div>
                            {
                                menuItems && menuItems.length > 0 && selected &&
                                menuItems.filter(item => item.value === selected) &&
                                <div style={{ display: 'flex', flexFlow: 'wrap', }}>
                                    {
                                        selected.map(selectedValue => (
                                            <Chip
                                                key={ selectedValue }
                                                label={this._handleGroupName(menuItems, selectedValue)}
                                                style={{ margin: '1px' }}
                                                size={ 'small' }
                                                clickable={ true }
                                                onDelete={ this._onDelete }

                                            />
                                        ) )
                                    }
                                </div>
                            }
                        </div>
                      )}
                    { ...selectProps }
                >
                    { (!menuItems || menuItems.length === 0) &&
                        <StyledMenuItem dense={ true } value=''><em>{ 'None' }</em></StyledMenuItem>
                    }

                    {
                        menuItems.map((item, index) =>
                            <StyledMenuItem
                                key={ index }
                                dense={ true }
                                value={ item.value ? item.value : item }
                            >
                                { item.label ? item.label : item }
                            </StyledMenuItem>
                        )
                    }
                </StyledSelect>
            </FormControl>
        )
    }
}

const StyledSelect = withStyles({
    icon: {
        color: 'rgba(45, 219, 172, 1)'
    },
    outlined: {
        color: '#505050',
        fontSize: '14px'
    },
    root: {
        // maxHeight: '94px',
        overflowY: 'auto'
    }
})(Select)

const StyledOutlinedInput = withStyles({
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'rgba(45, 219, 172, 0.4)'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'rgba(45, 219, 172, 0.8)'
        }
    }
})(OutlinedInput)

const StyledMenuItem = withStyles({
    root: {
        fontSize: '12px',
        color: 'rgba(56, 64, 78, 1)'
    }
})(MenuItem)

export default StyledMultipleChipSelect