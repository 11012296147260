import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { getAllUsers, deleteUser } from '../../../store/actions/companyDashboardActions'
import { Button } from '@material-ui/core'

class DeleteUser extends React.PureComponent {

    state = {
        userList: [],
        selectedUser: -1,
        isSnackbarOpen: false,
        isDeleteUserSuccessful: false,
        feedbackMessage: '',
        loading: false,
        isDialogOpen: false,
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        Promise.all([ getAllUsers() ])
            .then(results => {
                const users = results[0]

                const userList = users.map(u => ({ ...u, value: u.user_id, label: u.name }))

                this.setState({ userList, loading: false })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching users or groups.', loading: false })
            })
    }

    // Handle On Change
    _onChange = e => {
        this.setState({ [ e.target.name ]: e.target.value })
    }

    // Handle Confirmation Dialog
    _handleDialogOpen = () => {
        const { selectedUser } = this.state

        if (selectedUser && selectedUser !== -1) {
            this.setState({ isDialogOpen: true })
        } else {
            this.setState({ isSnackbarOpen: true, feedbackMessage: 'Select a user to delete.', })
        }
    }

    // Handle Dialog Close
    _handleDialogClose = () => {
        this.setState({ isDialogOpen: false })
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // On Submit
    _onSubmit = e => {
        e.preventDefault()

        // Set Loading
        this.setState({ loading: true })
        
        const { selectedUser } = this.state
    
        deleteUser(selectedUser)
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        isSnackbarOpen: true,
                        isDeleteUserSuccessful: res.status === 200,
                        feedbackMessage: res.message,
                        selectedUser: -1,
                        isDialogOpen: false
                    })

                    // Set Loading
                    this.setState({ loading: false })
                } else {
                    this.setState({
                        isSnackbarOpen: true,
                        isDeleteUserSuccessful: false,
                        feedbackMessage: 'Something went wrong.',
                        loading: false,
                        isDialogOpen: false
                    })
                }
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isDeleteUserSuccessful: false,
                    feedbackMessage: err.message,
                    loading: false,
                    isDialogOpen: false
                })
            })
    }

    render() {

        const { userList, selectedUser, isSnackbarOpen, isDeleteUserSuccessful, feedbackMessage, loading, isDialogOpen } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Typography
                        component='h1'
                        variant='h4'
                        align='center'
                        display='block'
                        style={{
                            color: '#505050',
                            marginBottom: '1rem'
                        }}
                    >
                        { 'Delete User' }
                    </Typography>

                    <form id='just-checking' onSubmit={ this._onSubmit } style={ formStyles }>
                        <Grid container={ true } spacing={ 3 }>
                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel required={ true }>{ 'Select User' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={[ { value: -1, label: 'None' }, ...userList ]}
                                    label='Select User'
                                    name='selectedUser'
                                    required={ true }
                                    value={ selectedUser }
                                    onChange={ this._onChange }
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <StyledSubmitButton
                                    // type='submit'
                                    fullWidth={ true }
                                    variant='contained'
                                    onClick={ this._handleDialogOpen }
                                >
                                    { 'Delete' }
                                </StyledSubmitButton>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={isDialogOpen}
                            onClose={this._handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            fullWidth={ true }
                        >
                            <DialogTitle id="alert-dialog-title">
                                { `Confirm Delete User?` }
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={ this._handleDialogClose } >
                                    { 'Cancel' }
                                </Button>
                                <StyledSubmitButton
                                    type='submit'
                                    variant='contained'
                                    form='just-checking'
                                >
                                    { 'Confirm' }
                                </StyledSubmitButton>
                            </DialogActions>
                        </Dialog>
                    </form>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isDeleteUserSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '50%',
    padding: '32px'
}

const formStyles = {
    padding: '32px 0px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
}

export default DeleteUser