import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import StyledInput from '../../common/StyledInput'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { validateEmail, validatePassword, validatePhoneNumber } from '../../../utils/utils'
import { getAllUsers, updateUser } from '../../../store/actions/companyDashboardActions'

// userRoleOptions are incremented by 1 since 0 is falsey in select menu.
class UpdateUser extends React.PureComponent {
    state = {
        userList: [],
        userRoleOptions: [
            { value: 1, label: 'Non-Admin' },
            { value: 2, label: 'Admin' }
        ],
        selectedUser: -1,
        userRole: 1,
        name: '',
        phone: '',
        email: '',
        password: '',
        error: {},
        isSnackbarOpen: false,
        isUpdateUserSuccessful: false,
        feedbackMessage: '',
        loading: false,
        passwordVisibility: false
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        // Get userList
        getAllUsers()
            .then(users => {
                const userList = users.map(u => ({ ...u, value: u.user_id, label: u.name }))
                this.setState({ userList, loading: false })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching users.', loading: false })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const { userList, selectedUser } = this.state

        // If selectedUser changes, autofill form inputs
        if(prevState.selectedUser !== selectedUser) {
            const user = userList.find(u => u.user_id === selectedUser)

            if(user) {
                this.setState({ userRole: user.is_admin + 1, name: user.name, phone: user.phone, email: user.email })
            }
        }
    }

    // Handle On Change
    _onChange = e => {
        this.setState({ [ e.target.name ]: e.target.value })
    }

    // Handle On Submit
    _onSubmit = e => {
        e.preventDefault()
        const { selectedUser, userRole, name, phone, email, password, error } = this.state

        // Validate Email
        const emailValidation = validateEmail(email)
        const passwordValidation = validatePassword(password)
        const phoneValidation = validatePhoneNumber(phone)

        // Set Errors
        if((email && !emailValidation.success) || (password && !passwordValidation.success) || (phone && !phoneValidation.success)) {
            this.setState({
                error: {
                    ...error,
                    email: emailValidation.message,
                    password: passwordValidation.message,
                    phone: phoneValidation.message
                }
            })

        } else {
            this.setState({ error: {} })
        }

        // Set Loading
        this.setState({ loading: true })

        // Validation Success!!
        updateUser({
            user_id: Number(selectedUser) !== -1 ? selectedUser : null,
            is_admin: Number(userRole) - 1,
            name,
            phone: phoneValidation.success ? phone : '',
            email: emailValidation.success ? email : '',
            password: passwordValidation.success ? password : ''
        })
            .then(res => {
                this.setState({
                    isSnackbarOpen: true,
                    isUpdateUserSuccessful: res.status === 200,
                    feedbackMessage: res.message,
                    error: {},
                    loading: false
                })

            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isUpdateUserSuccessful: false,
                    feedbackMessage: err.message,
                    loading: false
                })
            })
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // Handle Password Visibility
    _handlePasswordVisibility = () => {
        const { passwordVisibility } = this.state
        this.setState({ passwordVisibility: !passwordVisibility })
    }

    // Handle Mousedown Password
    _handleMouseDownPassword = e => {
        e.preventDefault()
    }

    render() {
        const { userList, userRoleOptions, selectedUser, userRole, name, phone, email, password, error, isSnackbarOpen, isUpdateUserSuccessful, feedbackMessage, loading, passwordVisibility } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Typography
                        component='h1'
                        variant='h4'
                        align='center'
                        display='block'
                        style={{
                            color: '#505050',
                            marginBottom: '2rem'
                        }}
                    >
                        { 'Update User' }
                    </Typography>

                    <form onSubmit={ this._onSubmit } style={ formStyles }>
                        <Grid container={ true } spacing={ 4 }>
                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Select User' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={[ { value: -1, label: 'None' }, ...userList ]}
                                    label='Select User'
                                    name='selectedUser'
                                    required={ true }
                                    value={ selectedUser }
                                    onChange={ this._onChange }
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel required={ true }>{ 'Select Role' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={ userRoleOptions }
                                    label='Select Role'
                                    name='userRole'
                                    required={ true }
                                    value={ userRole }
                                    onChange={ this._onChange }
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Name' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='name'
                                    type='text'
                                    value={ name }
                                    onChange={ this._onChange }
                                    placeholder='Enter name...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Phone' }</InputLabel>
                                <StyledInput
                                    error={ error.phone ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='phone'
                                    type='text'
                                    value={ phone }
                                    onChange={ this._onChange }
                                    placeholder='Enter phone number...'
                                    helperText={ error.phone ? error.phone : null }
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Email' }</InputLabel>
                                <StyledInput
                                    error={ error.email ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='email'
                                    type='text'
                                    autoComplete='email'
                                    value={ email }
                                    onChange={ this._onChange }
                                    helperText={ error.email ? error.email : null }
                                    placeholder='Enter email...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Password' }</InputLabel>
                                <StyledInput
                                    error={ error.password ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='password'
                                    type={ passwordVisibility ? 'text' : 'password' }
                                    value={ password }
                                    onChange={ this._onChange }
                                    helperText={ error.password ? error.password : null }
                                    placeholder='Enter password...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={ this._handlePasswordVisibility }
                                                onMouseDown={ this._handleMouseDownPassword }>
                                                { passwordVisibility ? <Visibility /> : <VisibilityOff /> }
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <StyledSubmitButton
                                    type='submit'
                                    fullWidth={ true }
                                    variant='contained'
                                >
                                    { 'Update User' }
                                </StyledSubmitButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isUpdateUserSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '50%',
    padding: '32px'
}

const formStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
}

export default UpdateUser