import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core'

// Import Styles
import './index.css'

// Import Components
import App from './App'
import MapStyleActionPanel from './components/Map/MapStyleActionPanel'

// Import Assets
import barikoiLogoWhite from './assets/bkoi_white.svg'

// Import App Theme
import theme from './theme'

// Connect Store
import store from './store/store'

// Render App
ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ThemeProvider theme={ theme }>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

/////////////////////////////////////////
// DOM Manipulations for Customization //
/////////////////////////////////////////

// Window.onload Functioalities
window.onload = () => {
  // Change Attribution
  changeAttributions()

  // Render Map Style Action Panel Into Map Control DOM
  renderMapStyleActionPanelIntoDom()
}

// Change Attributions
function changeAttributions() {
  const attrIntervalId = setInterval(() => {
    const attributionLogo = document.querySelector('.mapbox-attribution-container .attrition-logo .mapboxgl-ctrl-logo')
    const attributionLink = document.querySelector('.mapbox-attribution-container > div:last-child')

    // Check if Elements Found
    if(attributionLogo && attributionLink) {
      // New Attribution Logo
      const newAttributionLogo = document.createElement('a')
      newAttributionLogo.setAttribute('class', 'barikoigl-ctrl-logo')
      newAttributionLogo.setAttribute('target', '_blank')
      newAttributionLogo.setAttribute('rel', 'noopener noreferrer')
      newAttributionLogo.setAttribute('aria-label', 'Barikoi Logo')
      newAttributionLogo.style.marginLeft = '4px'

      // Add Barikoi Logo SVG
      const svg = document.createElement('img')
      svg.setAttribute('src', barikoiLogoWhite)
      svg.setAttribute('width', '40px')
      svg.setAttribute('height', '14px')
      svg.style.marginTop = '4px'
      newAttributionLogo.appendChild(svg)


      attributionLogo.parentNode.replaceChild(newAttributionLogo, attributionLogo)

      // Remove `improve this map` and `kepler.gl`
      attributionLink.children[0].remove()
      attributionLink.children[2].remove()

      // Remove `|` on the last attribution
      attributionLink.children[1].innerHTML = '© OpenStreetMap'

      // Clear Interval
      clearInterval(attrIntervalId)
    }
  })
}

// Render Map Style Action Panel Into Map Control DOM
export function renderMapStyleActionPanelIntoDom() {
  const mapStyleIntervalId = setInterval(() => {
    const mapControlContainer = document.querySelector('.map-control')
    const lastChild = document.querySelector('.map-control > div:last-child')

    if(mapControlContainer && lastChild) {
      // Create Map Style Container
      const mapStyleContainer = document.createElement('div')
      mapStyleContainer.className = lastChild.className + ' map-style-control'
      mapStyleContainer.style.width = '100%'
      mapStyleContainer.style.display = 'flex'
      mapStyleContainer.style.justifyContent = 'flex-end'
      mapStyleContainer.style.alignItems = 'center'

      // Append To Map Control Container
      mapControlContainer.appendChild(mapStyleContainer)

      // Render Map Style Control Component
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={ store }>
            <MapStyleActionPanel />
          </Provider>
        </React.StrictMode>,
        mapStyleContainer
      )

      // Clear Interval
      clearInterval(mapStyleIntervalId)
    }
  })
}