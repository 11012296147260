import React from 'react'
// import { format } from 'date-fns'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import StyledModal from '../../common/StyledModal'
import GeofenceTimeline from '../../common/GrofenceTimeline'
import { CircularProgress } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import dummyImg from '../../../assets/trace_logo.png'

class UserRoutePoints extends React.PureComponent {
    
    render() {
        const { routePoints, handleSelectedRoutePoint, loading } = this.props
        // console.log({ routePoints })
        return (                        
            <Box style={{ height: '100%', overflow: 'auto' }}>
                {
                    loading ?
                        <Box display={ 'flex' } justifyContent={ 'center' } alignItems={ 'center' } height={ '100%' }>
                            <CircularProgress />
                        </Box>
                        :
                        (
                            <Box display={ 'contents' } >
                                {
                                    routePoints && routePoints.length > 0 ?
                                    routePoints.map((routePoint, index) => {
                                        return (
                                            <Box key={ index + 'rBox' }>
                                                <RouteInformation key={ index + 'route-information' } data={ routePoint } handleSelectedRoutePoint={ handleSelectedRoutePoint }/>
                                                <Divider key={ index + ' key' } />                                                
                                            </Box>                                
                                            )
                                        })
                                        :
                                        (
                                            <Box display={ 'flex' } justifyContent={ 'center' } alignItems={ 'center' } height={ '100%' }>
                                                { 'Route Points unavailable.' }
                                            </Box>
                                        )
                                }
                            </Box>
                        )
                }
            </Box>
        )
    }
}

class RouteInformation extends React.PureComponent {
    state = {
        isModalOpen: false,
        routePointInfo: {}
    }
    // Handle Modal Open and Passed Route Information
    _handleModalOpen = (e, routePointInfo) => {
        e.preventDefault()
        if (routePointInfo && routePointInfo.images) {
            this.setState({ isModalOpen: true, routePointInfo })
        }
    }

    // Close Modal
    _handleModalClose = () => {
        setTimeout(() => {
            this.setState({ isModalOpen: false, routePointInfo: {} })
        }, 0)
    }

    _test = (e, data) => {
        console.log({ data })
    }
    render() {
        const { isModalOpen, routePointInfo } = this.state
        const { data } = this.props                
        return (
            
            <Box style={{ padding: '16px' }} >
                {
                    data &&
                        <Grid container={true} spacing={1} alignItems='stretch'>
                            <Grid item={true} md={9} >
                                <Typography
                                    variant='body2'
                                    color='textPrimary'
                                    align='left'
                                    gutterBottom={true}
                                >
                                    { data.shopname ? data.shopname : 'N/A' }
                                </Typography>                        
                                <GeofenceTimeline data={ data.geofence } shopname={ data.shopname } />
                            </Grid>
                            <Grid item={true} md={3} style={{ padding: 0, }} onClick={(e) => this._handleModalOpen(e, data)} >
                                <Paper elevation={ 3 } style={{ background: 'grey', cursor: 'pointer', height: '100%', borderRadius: '4px', overflow: 'hidden' }}>
                                    {
                                        data.images && data.images.length > 0 ?
                                            (
                                                // <img style={imgStyles} alt='' src={data.images[0].url} />
                                                <LazyLoadImage
                                                    alt={ 'test' }
                                                    effect={ 'blur' }
                                                    src={ data.images[0].url }
                                                    style={ imgStyles }
                                                    placeholderSrc={ dummyImg }
                                                />
                                            )
                                            :
                                            (
                                                <span>{`N/A`}</span>
                                            )
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                }

                <StyledModal
                    routePointInfo={ routePointInfo }
                    isModalOpen={ isModalOpen }
                    closeModal={ this._handleModalClose }

                />
            </Box>
        )
    }
}

const imgStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',    
}

export default UserRoutePoints