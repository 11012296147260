import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'

class StyledSnackbar extends React.PureComponent {
    render() {
        const { open, isSuccessful, message, onClose, ...restProps } = this.props

        return (
            <Snackbar
                open={ open }
                autoHideDuration={ 5000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={ Slide }
                onClose={ onClose }
                { ...restProps }
            >
                <MuiAlert
                    variant='filled'
                    elevation={ 5 }
                    severity={ isSuccessful ? 'success' : 'error' }
                    onClose={ onClose }
                >
                    { message }
                </MuiAlert>
            </Snackbar>
        )
    }
}

// Prop Types
StyledSnackbar.propTypes = {
    open: PropTypes.bool,
    isSuccessful: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func
}

StyledSnackbar.defaultProps = {
    open: false,
    isSuccessful: false,
    message: '',
    onClose: () => null
}

export default StyledSnackbar