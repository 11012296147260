import React from 'react'
import PropTypes from 'prop-types'

class NativeSelectMenu extends React.PureComponent {
  render() {
    const { name, options, value, onChange, ...restProps } = this.props

    return (
      <select name={ name } value={ value } onChange={ onChange } { ...restProps }>
        {
          options.map((o, i) =>
            <option key={ i } value={ o.value }>{ o.label }</option>
          )
        }
      </select>
    )
  }
}

// Prop Types
NativeSelectMenu.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    label: PropTypes.string
  })),
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func
}

NativeSelectMenu.defaultProps = {
  name: '',
  options: [],
  value: '',
  onChange: () => null
}

export default NativeSelectMenu