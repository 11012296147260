import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import StyledDrawer from '../../common/StyledDrawer'
// import LogoutButton from './LogoutButton'
import Header from './Header'
import Footer from './Footer'
import MenuBody from './MenuBody'

// Import Actions & Methods
import { setIsSidePanelOpen } from '../../../store/actions/sidePanelActions'
import UserActivity from './UserActivity'

class Menu extends React.PureComponent {
    state = {
        selectedMenuItem: ''
    }

    componentDidMount() {
        const selectedMenuItem = this._getLocationMenuBasePathName()
        this.setState({ selectedMenuItem })
    }

    componentDidUpdate(prevProps) {
        const { pathname } = this.props.location

        // If pathname changes in location
        if(prevProps.location.pathname !== pathname) {
            const selectedMenuItem = this._getLocationMenuBasePathName()
            this.setState({ selectedMenuItem })
        }
    }

    // Get location menu Base pathname
    _getLocationMenuBasePathName = () => {
        const { pathname } = this.props.location
        const sections = pathname.split('/')

        if(sections.length > 1) {
            return sections[1]
        }

        return ''
    }

    // Handle toggle Side Panel
    openSidePanel = () => {
        this.props.dispatch( setIsSidePanelOpen(true) )
    }

    closeSidePanel = () => {
        this.props.dispatch( setIsSidePanelOpen(false) )
    }

    render() {
        const { selectedMenuItem } = this.state
        const { isSidePanelOpen } = this.props

        return (
            <Box>
                { !isSidePanelOpen &&
                    <IconButton style={ iconStyles } onClick={ this.openSidePanel } >
                        <ChevronRightIcon style={{ fontSize: '28px', color: '#f5f5f5', padding: '6px' }} />
                    </IconButton>
				}
                <StyledDrawer
                    open={ isSidePanelOpen }
                    onClose={ this.closeSidePanel }
                    variant='persistent'
                >
                    <Box { ...drawerContainerStyles }>
                        <Header title={ 'Trace Dashboard' } closeSidePanel={ this.closeSidePanel } />
                        <MenuBody selectedMenuItem={selectedMenuItem} />
                        <UserActivity />
                        {/* <LogoutButton /> */}
                        <Footer footerTitle={ 'Barikoi Technologies Limited' } />
                    </Box>
                </StyledDrawer>

            </Box>
        )
    }
}

// JSS Styles
const drawerContainerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden'
}

const iconStyles = {
    position: 'absolute',
    zIndex: 10,
    left: '12px',
    top: '12px',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    background: 'rgba(56, 64, 78, 0.8)'
}

const mapStateToProps = state => ({ isSidePanelOpen: state.app.sidePanel.isSidePanelOpen })
const mapDispatchToProps = dispatch => ({ dispatch })

// export default withRouter(Menu)
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu))