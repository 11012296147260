import { combineReducers } from 'redux'
import { keplerGlReducer,  } from 'kepler.gl/reducers'
import appReducer from './appReducer'

// // Kepler GL Custom Reducer
// const customizedKeplerGlReducer = keplerGlReducer.initialState({
//     uiState: {
//         mapControls: {
//             ...uiStateUpdaters.DEFAULT_MAP_CONTROLS,
//             splitMap: {
//                 show: false,
//                 active: false
//             },
//             mapDraw: {
//                 show: false,
//                 active: false
//             },
//             mapLocale: {
//                 show: false,
//                 active: false
//             }
//         }
//     }
// })

const rootReducer = combineReducers({
    keplerGl: keplerGlReducer,
    app: appReducer
})

export default rootReducer