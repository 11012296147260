import React from 'react'
import { connect } from 'react-redux'
import { format, parseISO } from 'date-fns'
import { withRouter } from 'react-router-dom'
import { MAP_CONFIG } from '../../../App.config'
import _throttle from 'lodash/throttle'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledDateTimePicker from '../../common/StyledDateTimePicker'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledLinearProgress from '../../common/StyledLinearProgress'
// import UserTripsList from './UserTripsList'
import KeplerGl from '../../KeplerGl/KeplerGl'
import ErrorBoundary from '../../common/ErrorBoundary'

// Import Actions & Methods
import { loadInitialCustomMap } from '../../../store/actions/mapActions'
import { setLayerSizeWithZoomLevel } from '../../../store/actions/layerActions'
import { getAllUsers, getUserTripsWithinTimeRange, addUserTripsToMap, filterUserTrips, getApiKeyFromToken, getActiveUserTrips } from '../../../store/actions/companyDashboardActions'
import StyledDataGrid2 from '../../common/StyledDataGrid2'

class UserTrips extends React.PureComponent {
    state = {
        mapWidth: 400,
        mapHeight: 400,
        userList: [],
        selectedUser: -1,
        selectedUserName: 'ALL',
        startTime: new Date(),
        endTime: new Date(),
        tripList: [],
        selectedTrip: -1,
        isSnackbarOpen: false,
        isGetUserTripsSuccessful: false,
        feedbackMessage: '',
        loading: false,
        fileName: 'Barikoi Trace(empty)'
    }

    componentDidMount() {
        const { search } = this.props.location

        // Set Loading
        this.setState({ loading: true })

        // Update Map Size
        this._updateMapSize()

        // Add Window Resize Event
        this.onResize = _throttle(this._updateMapSize, 0, { trailing: true })
        window.addEventListener('resize', this.onResize)

        // Load Initial Custom Map Without Data
        this.props.dispatch(loadInitialCustomMap())

        // Get userList
        getAllUsers()
            .then(users => {
                const userList = users.map(u => ({ ...u, value: u.user_id, label: u.name }))
                this.setState({ userList, loading: false })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching users.', loading: false })
            })

        // Extract Query Params if exists
        const queryParams = this._parseQueryParams(search)
        if (Object.keys(queryParams).length > 0) {                            
            this.setState({
                selectedUser: queryParams.user_id && queryParams.user_id !== 'ALL' ?
                    Number(queryParams.user_id)
                    :
                    -1,
                startTime: queryParams.start_time ? new Date(queryParams.start_time) : new Date(),
                endTime: queryParams.end_time ? new Date(queryParams.end_time) : new Date()
            })            
        }        
        
        setTimeout(() => {            
            if (!queryParams.active_trips && queryParams.user_id) {
                this._onSubmit()
            } else if (queryParams.active_trips) {
                this._getActiveTrips()
            }
        }, 10)
    }

    componentDidUpdate(prevProps, prevState) {
        const { zoom } = this.props
        const { selectedUser, tripList, selectedTrip } = this.state

        // Set Point Layer Radius with Zoom Level Change
        if (prevProps.zoom !== zoom) {
            this.props.dispatch(setLayerSizeWithZoomLevel(zoom))
        }

        // If selectedUser Changes in State
        if (prevState.selectedUser !== selectedUser) {
            const selectedUserName = this._getUserNameFromUserId(selectedUser)
            this.setState({ selectedUserName })
        }

        // If Selected Trip Changes in state
        if (prevState.selectedTrip !== selectedTrip) {
            let tripInfo = null
            if (selectedTrip !== -1) {
                const trip = tripList.find(t => t.value === selectedTrip)
                tripInfo = trip.tripInfo
            }

            // Filter trips on map
            this.props.dispatch(filterUserTrips(tripInfo))
        }
    }

    // Update Map Size
    _updateMapSize = () => {
        const el = document.getElementById('map-container')
        if (el) {
            this.setState({ mapWidth: el.clientWidth - 8, })
        }
    }

    // On Input Change
    _onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // On Start Date-Time Input Change
    _onStartDateTimeChange = startTime => {
        this.setState({ startTime })
    }

    // On End Date-Time Input Change
    _onEndDateTimeChange = endTime => {
        this.setState({ endTime })
    }

    // Get Active Trips
    _getActiveTrips = () => {        
        // Set Loading
        this.setState({ loading: true, tripList: [] })

        // get api key
        getApiKeyFromToken()
            .then(apiKey => {
                getActiveUserTrips({ user_id: 'ALL', api_key: apiKey, unit: 'KM' })
                    .then(res => {
                        // Error Message
                        if(!res.data && res.message) {
                            throw new Error(res.message)
                        }

                        if (res.data) {
                            const { selectedUser, startTime, endTime, userList } = this.state
                            const filteredEmptyGeoJson = res.data.trips.filter(t => {
                                const checkCoOrdinates = t.trip_info.path.geo_json && t.trip_info.path.geo_json.coordinates && t.trip_info.path.geo_json.coordinates.length > 0
                                return checkCoOrdinates
                            })                            

                            if (filteredEmptyGeoJson && filteredEmptyGeoJson.length > 0) {
                                this.props.dispatch(addUserTripsToMap(filteredEmptyGeoJson))

                                const tripList = res.data.trips.map((d, i) => ({
                                    value: d.trip_info.trip_id,
                                    label: d.trip_info.tag + '(' + d.trip_info.start_time + ' - ' + d.trip_info.end_time + ')',
                                    tripInfo: d.trip_info,

                                    id: i + 1,
                                    name: d.trip_info.user_name,
                                    tripId: d.trip_info.trip_id,
                                    distance: d.trip_info.distance && d.trip_info.unit ? d.trip_info.distance + ' ' + d.trip_info.unit : '-',
                                    startTime: d.trip_info.start_time ? format(parseISO(d.trip_info.start_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    endTime: d.trip_info.end_time ? format(parseISO(d.trip_info.end_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    tags: d.trip_info.tag ? d.trip_info.tag : '-'
                                }))
                                // console.log('trips1', tripList)
                                const selectedUsername = selectedUser === -1 ? 'All' : userList && userList.length > 0 && userList.find(d => d.user_id === selectedUser).name
                                
                                const fileName = startTime && endTime && selectedUsername && `${format(startTime, 'dd-MMM-yyyy') + '_' + format(endTime, 'dd-MMM-yyyy') + '_' + selectedUsername}`

                                // To Detect Array Change
                                this.setState({ tripList: [] })
    
                                setTimeout(() => {
                                    this.setState({ tripList, loading: false, fileName })
                                })
                            } else {                                
                                const tripList = res.data.trips.map(d => ({
                                    value: d.trip_info.trip_id,
                                    label: d.trip_info.tag + '(' + d.trip_info.start_time + ' - ' + d.trip_info.end_time + ')',
                                    tripInfo: d.trip_info,

                                    id: d.trip_info.user_id,
                                    name: d.trip_info.user_name,
                                    tripId: d.trip_info.trip_id,
                                    distance: d.trip_info.distance && d.trip_info.unit ? d.trip_info.distance + ' ' + d.trip_info.unit : '-',
                                    startTime: d.trip_info.start_time ? format(parseISO(d.trip_info.start_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    endTime: d.trip_info.end_time ? format(parseISO(d.trip_info.end_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    tags: d.trip_info.tag ? d.trip_info.tag : '-'
                                }))                                
    
                                // To Detect Array Change
                                this.setState({ tripList: [] })
    
                                setTimeout(() => {
                                    this.setState({ tripList, loading: false })
                                })                                
                            }                           
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.setState({
                            isSnackbarOpen: true,
                            isGetUserTripsSuccessful: false,
                            feedbackMessage: err.message ? err.message : err,
                            loading: false
                        })
                    })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isGetUserTripsSuccessful: false,
                    feedbackMessage: err.message ? err.message : err,
                    loading: false
                })
            })
    }


    // On Form Submit
    _onSubmit = e => {
        if (e) {
            e.preventDefault()
        }

        const { selectedUser, startTime, endTime, userList } = this.state
        
        // Validate Data
        if(!startTime || !endTime) {
            this.setState({
                isSnackbarOpen: true,
                isGetUserTripsSuccessful: false,
                feedbackMessage: 'Select valid inputs.'
            })

            return
        }

        if(endTime - startTime < 0) {
            this.setState({
                isSnackbarOpen: true,
                isGetUserTripsSuccessful: false,
                feedbackMessage: 'End Time must be later than Start Time.'
            })

            return
        }

        // Set Loading
        this.setState({ loading: true, tripList: [] })

        // Get API_KEY from Token
        getApiKeyFromToken()
            .then(apiKey => {
                // Get User Trips within Time Range
                getUserTripsWithinTimeRange({
                    user_id: selectedUser === -1 ? 'ALL' : Number(selectedUser),
                    start_time: format(startTime, 'yyyy-MM-dd')  + ' 00:00:00',
                    end_time: format(endTime, 'yyyy-MM-dd') + ' 23:59:59',
                    api_key: apiKey,
                    unit: 'KM'
                })
                    .then(res => {                        
                        // Error Message
                        if(!res.data && res.message) {
                            throw new Error(res.message)
                        }

                        if (res.data) {
                            const filteredEmptyGeoJson = res.data.trips.filter(t => {
                                const checkCoOrdinates = t.trip_info.path.geo_json.coordinates && t.trip_info.path.geo_json.coordinates.length > 0
                                return checkCoOrdinates
                            })

                            if (filteredEmptyGeoJson && filteredEmptyGeoJson.length > 0) {
                                this.props.dispatch(addUserTripsToMap(filteredEmptyGeoJson))

                                const tripList = res.data.trips.map((d, i) => ({
                                    value: d.trip_info.trip_id,
                                    label: d.trip_info.tag + '(' + d.trip_info.start_time + ' - ' + d.trip_info.end_time + ')',
                                    tripInfo: d.trip_info,

                                    id: i + 1,
                                    name: d.trip_info.user_name,
                                    tripId: d.trip_info.trip_id,
                                    distance: d.trip_info.distance && d.trip_info.unit ? d.trip_info.distance + ' ' + d.trip_info.unit : '-',
                                    startTime: d.trip_info.start_time ? format(parseISO(d.trip_info.start_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    endTime: d.trip_info.end_time ? format(parseISO(d.trip_info.end_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    tags: d.trip_info.tag ? d.trip_info.tag : '-'
                                }))
                                // console.log('trips1', tripList)
                                const selectedUsername = selectedUser === -1 ? 'All' : userList && userList.length > 0 && userList.find(d => d.user_id === selectedUser).name
                                
                                const fileName = startTime && endTime && selectedUsername && `${format(startTime, 'dd-MMM-yyyy') + '_' + format(endTime, 'dd-MMM-yyyy') + '_' + selectedUsername}`

                                // To Detect Array Change
                                this.setState({ tripList: [] })
    
                                setTimeout(() => {
                                    this.setState({ tripList, loading: false, fileName })
                                })
                            } else {
                                // console.log('nai', filteredEmptyGeoJson)
                                const tripList = res.data.trips.map(d => ({
                                    value: d.trip_info.trip_id,
                                    label: d.trip_info.tag + '(' + d.trip_info.start_time + ' - ' + d.trip_info.end_time + ')',
                                    tripInfo: d.trip_info,

                                    id: d.trip_info.user_id,
                                    name: d.trip_info.user_name,
                                    tripId: d.trip_info.trip_id,
                                    distance: d.trip_info.distance && d.trip_info.unit ? d.trip_info.distance + ' ' + d.trip_info.unit : '-',
                                    startTime: d.trip_info.start_time ? format(parseISO(d.trip_info.start_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    endTime: d.trip_info.end_time ? format(parseISO(d.trip_info.end_time), 'dd-MMM-yyyy h:mm aa') : '-',
                                    tags: d.trip_info.tag ? d.trip_info.tag : '-'
                                }))
                                // console.log('trips2', tripList)
    
                                // To Detect Array Change
                                this.setState({ tripList: [] })
    
                                setTimeout(() => {
                                    this.setState({ tripList, loading: false })
                                })
                                throw new Error('Trips not found.')
                            }                           
                        }
                        
                    })
                    .catch(err => {
                        console.error(err)
                        this.setState({
                            isSnackbarOpen: true,
                            isGetUserTripsSuccessful: false,
                            feedbackMessage: err.message ? err.message : err,
                            loading: false
                        })
                    })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isGetUserTripsSuccessful: false,
                    feedbackMessage: err.message ? err.message : err,
                    loading: false
                })
            })
    }

    // Set Selected Trip
    _setSelectedTrip = selectedTrip => {        
        this.setState({ selectedTrip })
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // Get User Name from User Id
    _getUserNameFromUserId = userId => {
        if(userId === -1) {
            return 'ALL'
        }

        const { userList } = this.state

        const user = userList.find(u => u.user_id === userId)

        if(!user) {
            return ''
        }

        return user.name ? user.name : ''
    }

    // Parse Query Params
    _parseQueryParams = query => {
        const queryString = query.slice(1, query.length)
        const keyValueStrings = queryString.split('&')
        const params = Object.fromEntries(keyValueStrings.map(k => k.split('=')))
        if (params && params.start_time && params.end_time) {
            params['start_time'] = params['start_time'].replace('%20', ' ')
            params['end_time'] = params['end_time'].replace('%20', ' ')
        }
        
        return params
    }

    render() {
        const { mapWidth, mapHeight, userList, selectedUser, startTime, endTime, tripList, isSnackbarOpen, isGetUserTripsSuccessful, feedbackMessage, loading, fileName } = this.state
        
        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Grid container={ true } spacing={ 2 }>                
                    <Grid item={ true } xs={ 12 }>
                        <Paper elevation={ 4 } style={ paperStyles }>
                            <Box width='100%'>
                                <Paper variant='outlined' style={ topPaperStyles }>
                                    <form onSubmit={ this._onSubmit } style={ formStyles }>
                                        <Grid container={ true } spacing={ 2 } style={{ padding: '16px' }}>
                                            <Grid item={ true } xs={ 12 } sm={ 3 }>
                                                <InputLabel required={ true } style={{ fontSize: '14px' }}>{ 'Select User' }</InputLabel>
                                                <StyledSelectMenuVersion2
                                                    menuItems={[ { value: -1, label: 'ALL' }, ...userList ]}
                                                    label='Select User'
                                                    name='selectedUser'
                                                    required={ true }
                                                    value={ selectedUser }
                                                    onChange={ this._onChange }
                                                    selectProps={{ style: { height: '36px' }}}
                                                />
                                            </Grid>

                                            <Grid item={ true } xs={ 12 } sm={ 3 }>
                                                <InputLabel required={ true } style={{ fontSize: '14px' }}>{ 'Start Time' }</InputLabel>
                                                <StyledDateTimePicker
                                                    value={ startTime }
                                                    onChange={ this._onStartDateTimeChange }
                                                    inputProps={{ required: true, style: { height: '36px' } }}
                                                />
                                            </Grid>

                                            <Grid item={ true } xs={ 12 } sm={ 3 }>
                                                <InputLabel required={ true } style={{ fontSize: '14px' }}>{ 'End Time' }</InputLabel>
                                                <StyledDateTimePicker
                                                    value={ endTime }
                                                    onChange={ this._onEndDateTimeChange }
                                                    inputProps={{ required: true, style: { height: '36px' } }}
                                                />
                                            </Grid>

                                            <Grid item={ true } xs={ 12 } sm={ 3 } style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                <StyledSubmitButton
                                                    type='submit'
                                                    fullWidth={ true }
                                                    variant='contained'
                                                    style={{ marginBottom: '4px', height: '36px' }}
                                                >
                                                    { 'Get Trips' }
                                                </StyledSubmitButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Paper>
                            </Box>

                            <Box width='100%' height='100%' display='flex' flexDirection='row' justifyContent='center' alignItems='stretch'>
                                {/* <Paper elevation={ 4 } style={{ padding: '16px', width: '100%' }}>                                     */}
                                    <StyledDataGrid2
                                        title={ `User Trips (Table)` }
                                        columns={[
                                            { field: 'name', headerName: 'Name', flex: 1 },
                                            { field: 'tripId', headerName: 'Trip ID', flex: 0.5 },
                                            { field: 'distance', headerName: 'Distance', flex: 0.5 },
                                            { field: 'startTime', headerName: 'Start Time', flex: 0.8 },
                                            { field: 'endTime', headerName: 'End Time', flex: 0.8 },
                                            { field: 'tags', headerName: 'Tags', flex: 2 },
                                        ]}
                                        rows={ tripList }
                                        toolbar={ true }
                                        setSelectedTrip={ this._setSelectedTrip }
                                        fileName={ fileName }
                                    />
                                {/* </Paper> */}

                                {/* <Paper variant='outlined' style={ paperRightStyles }>
                                    <Grid container={ true } spacing={ 2 }>
                                        <Box width='100%' marginBottom='0.5rem'>
                                            <Typography
                                                component='h1'
                                                variant='h4'
                                                align='center'
                                                display='block'
                                                style={{
                                                    color: '#505050',
                                                    fontSize: '24px'
                                                }}
                                            >
                                                { 'User Trips' }
                                            </Typography>
                                        </Box>

                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <UserTripsList
                                                downloadFileName={
                                                    `${ selectedUser }_${ selectedUserName }_trips_(${ format(startTime, 'yyyy-MM-dd HH:mm:ss') } to ${ format(endTime, 'yyyy-MM-dd HH:mm:ss') })`
                                                }
                                                userId={ selectedUser !== -1 ? Number(selectedUser) : '' }
                                                userName={ selectedUserName }
                                                tripList={ tripList }
                                                selectedTrip={ selectedTrip }
                                                setSelectedTrip={ this._setSelectedTrip }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>                         */}
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item={ true } xs={ 12 }>
                        <Paper id='map-container' variant='outlined' style={ paperLeftStyles }>
                            <ErrorBoundary>
                                <KeplerGl
                                    id='map'
                                    mapboxApiAccessToken={ MAP_CONFIG.MAPBOX_TOKEN }
                                    width={ mapWidth }
                                    height={ mapHeight }
                                    mint={ true }
                                />
                            </ErrorBoundary>
                        </Paper>
                    </Grid>
                </Grid>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isGetUserTripsSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

const paperStyles = {
    width: '100%',
    height: '500px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch'
}

const topPaperStyles = {
    width: '100%',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperLeftStyles = {
    width: '100%',
    // height: '44vh',
    // marginRight: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px'
}

// const paperRightStyles = {
//     padding: '1rem',
//     width: '40%',
//     marginLeft: '0.5rem',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     overflow: 'auto'
// }

const formStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapStateToProps = state => ({
    zoom: state.keplerGl.map ? state.keplerGl.map.mapState.zoom : 0
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTrips))