import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import StyledInput from '../../common/StyledInput'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { getCompanySettings, updateCompanySettings } from '../../../store/actions/companyDashboardActions'
import { validateTrackingDays } from '../../../utils/utils'

// offlineSyncOptions, settingsModeOptions are incremented by 1 since 0 is falsey in select menu.
class Settings extends React.PureComponent {
    state = {
        offlineSyncOptions: [
            { value: 1, label: 'Off' },
            { value: 2, label: 'On' }
        ],
        settingsModeOptions: [
            { value: 1, label: 'Manual' },
            { value: 2, label: 'Always On' }
        ],
        offlineSync: 1,
        settingsMode: 1,
        updateTimeInterval: 0,
        distanceInterval: 0,
        accuracyFilter: 0,
        trackingDays: '',
        trackingStartTime: '',
        trackingEndTime: '',
        error: {},
        isSnackbarOpen: false,
        isUpdateSettingsSuccessful: false,
        feedbackMessage: '',
        loading: false
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        // Get Company Settings
        getCompanySettings()
            .then(res => {
                this.setState({
                    offlineSync: res.data.company_settings.offline_sync + 1,
                    settingsMode: res.data.company_settings.settings_mode + 1,
                    updateTimeInterval: res.data.company_settings.update_time_interval,
                    distanceInterval: res.data.company_settings.distance_interval,
                    accuracyFilter: res.data.company_settings.accuracy_filter,
                    trackingDays: res.data.company_settings.tracking_days,
                    trackingStartTime: res.data.company_settings.tracking_start_time,
                    trackingEndTime: res.data.company_settings.tracking_end_time,
                    loading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching settings.', loading: false })
            })
    }

    // On Change
    _onChange = e => {
        const { error } = this.state
        this.setState({ [ e.target.name ]: e.target.value, error: { ...error, [ e.target.name ]: '' } })
    }

    // On Submit
    _onSubmit = e => {
        e.preventDefault()

        const { offlineSync, settingsMode, updateTimeInterval, distanceInterval, accuracyFilter, trackingDays, trackingStartTime, trackingEndTime } = this.state

        // Validation
        const error = {}
        const trackingDaysValidation = validateTrackingDays(trackingDays)

        if(Number(updateTimeInterval) < 0) {
            error.updateTimeInterval = 'Must be 0 or higher.'
        }

        if(Number(distanceInterval) < 0) {
            error.distanceInterval = 'Must be 0 or higher.'
        }

        if(Number(accuracyFilter) < 0) {
            error.accuracyFilter = 'Must be 0 or higher.'
        }

        if(trackingDays && !trackingDaysValidation.success) {
            error.trackingDays = trackingDaysValidation.message
        }

        // Set Errors
        this.setState({ error })

        // Update Settings
        const updatedSettings = {
            offline_sync: String(offlineSync - 1),
            settings_mode: String(settingsMode - 1),
            update_time_interval: Number(updateTimeInterval) >= 0 ? updateTimeInterval : null,
            distance_interval: Number(distanceInterval) >= 0 ? distanceInterval : null,
            accuracy_filter: Number(accuracyFilter) >= 0 ? accuracyFilter : null,
            tracking_days: trackingDaysValidation.success ? trackingDays : null,
            tracking_start_time: trackingStartTime,
            tracking_end_time: trackingEndTime
        }

        // Set Loading
        this.setState({ loading: true })

        updateCompanySettings(updatedSettings)
            .then(res => {
                this.setState({
                    isSnackbarOpen: true,
                    isUpdateSettingsSuccessful: res.status === 200,
                    feedbackMessage: res.message ? res.message : 'Settings updated successfully.',
                    error: {},
                    loading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isUpdateSettingsSuccessful: false,
                    feedbackMessage: err.response.message,
                    loading: false
                })
            })
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    render() {
        const { offlineSyncOptions, settingsModeOptions, offlineSync, settingsMode, updateTimeInterval, distanceInterval, accuracyFilter, trackingDays, trackingStartTime, trackingEndTime, isSnackbarOpen, isUpdateSettingsSuccessful, feedbackMessage, error, loading } = this.state

        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Typography
                        component='h1'
                        variant='h4'
                        align='center'
                        display='block'
                        style={{
                            color: '#505050',
                            marginBottom: '2rem',
                        }}
                    >
                        { 'Update Settings' }
                    </Typography>

                    <form onSubmit={ this._onSubmit } style={ formStyles }>
                        <Grid container={ true } spacing={ 4 }>
                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Offline Sync' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={ offlineSyncOptions }
                                    label='Offline Sync'
                                    name='offlineSync'
                                    required={ true }
                                    value={ offlineSync }
                                    onChange={ this._onChange }
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Settings Mode' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={ settingsModeOptions }
                                    label='Settings Mode'
                                    name='settingsMode'
                                    required={ true }
                                    value={ settingsMode }
                                    onChange={ this._onChange }
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Update Time Interval' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='updateTimeInterval'
                                    type='number'
                                    value={ updateTimeInterval }
                                    onChange={ this._onChange }
                                    placeholder='Enter time interval...'
                                    error={ error.updateTimeInterval ? true : false }
                                    helperText={ error.updateTimeInterval ? error.updateTimeInterval : null }
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Distance Interval' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='distanceInterval'
                                    type='number'
                                    value={ distanceInterval }
                                    onChange={ this._onChange }
                                    placeholder='Enter distance interval...'
                                    error={ error.distanceInterval ? true : false }
                                    helperText={ error.distanceInterval ? error.distanceInterval : null }
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Accuracy Filter' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='accuracyFilter'
                                    type='number'
                                    value={ accuracyFilter }
                                    onChange={ this._onChange }
                                    placeholder='Enter accuracy filter...'
                                    error={ error.accuracyFilter ? true : false }
                                    helperText={ error.accuracyFilter ? error.accuracyFilter : null }
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Tracking Days' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='trackingDays'
                                    type='text'
                                    value={ trackingDays }
                                    onChange={ this._onChange }
                                    placeholder='Enter tracking days...'
                                    error={ error.trackingDays ? true : false }
                                    helperText={ error.trackingDays ? error.trackingDays : null }
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Tracking Start Time' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='trackingStartTime'
                                    type='time'
                                    value={ trackingStartTime }
                                    onChange={ this._onChange }
                                    placeholder='Enter tracking start time...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 6 }>
                                <InputLabel>{ 'Tracking End Time' }</InputLabel>
                                <StyledInput
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='trackingEndTime'
                                    type='time'
                                    value={ trackingEndTime }
                                    onChange={ this._onChange }
                                    placeholder='Enter tracking end time...'
                                    inputProps={{
                                        style: {
                                            color: '#505050',
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <StyledSubmitButton
                                    type='submit'
                                    fullWidth={ true }
                                    variant='contained'
                                >
                                    { 'Update Settings' }
                                </StyledSubmitButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isUpdateSettingsSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '50%',
    padding: '32px'
}

const formStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
}

export default Settings