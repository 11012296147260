import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Import Components
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'

class MenuListItem extends React.PureComponent {
    render() {
        const { id, title, Icon, selected, ...restProps } = this.props

        return (
            <Link to={ `/${ id }` } style={{ textDecoration: 'none' }}>
                <ListItem id={ id } button={ true } selected={ selected } onClick={ this.props.onClick } { ...restProps }>
                    <ListItemIcon>
                        <Icon
                            fontSize={ restProps.dense ? 'small' : 'default' }
                            style={{ color: selected ? '#f5f5f5' : '#c0c0c0' }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={ title }
                        primaryTypographyProps={{
                            style: {
                                color: selected ? '#f5f5f5' : '#c0c0c0',
                                fontWeight: selected ? '600' : '500',
                            }
                        }}
                    />
                </ListItem>
            </Link>
        )
    }
}

// Prop Types
MenuListItem.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.oneOfType([ PropTypes.element, PropTypes.node ]),
    selected: PropTypes.bool,
    onClick: PropTypes.func
}

MenuListItem.defaultProps = {
    id: 'list-item',
    title: 'List Item',
    Icon: PersonIcon,
    selected: false,
    onClick: () => null
}

export default MenuListItem