import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledSelectMenuVersion2 from '../../common/StyledSelectMenuVersion2'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { getAllUsers, getAllGroups, assignUserToGroup } from '../../../store/actions/companyDashboardActions'
import StyledMultipleChipSelect from '../../common/StyledMultipleChipSelect'

class AssignUserToGroup extends React.PureComponent {
    state = {
        userList: [],
        groupList: [],
        selectedUser: [],
        selectedGroup: -1,
        isSnackbarOpen: false,
        isAssignUserToGroupSuccessful: false,
        feedbackMessage: '',
        loading: false
    }

    componentDidMount() {
        // Set Loading
        this.setState({ loading: true })

        Promise.all([ getAllUsers(), getAllGroups() ])
            .then(results => {
                const users = results[0]
                const groups = results[1]

                const userList = users.map(u => ({ ...u, value: u.user_id, label: u.name }))
                const groupList = groups.map(g => ({ ...g, value: g.id, label: g.group_name }))

                this.setState({ userList, groupList, loading: false })
            })
            .catch(err => {
                console.error(err)
                this.setState({ isSnackbarOpen: true, feedbackMessage: 'Error fetching users or groups.', loading: false })
            })
    }

    // Handle On Change
    _onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // On Form Submit
    _onSubmit = e => {
        e.preventDefault()

        const { selectedUser, selectedGroup } = this.state

        // Set Loading
        this.setState({ loading: true })

        if (selectedUser && selectedUser.length > 0 && selectedGroup && selectedGroup !== -1) {
            selectedUser.forEach(( userId, index ) => {
                assignUserToGroup(selectedUser !== -1 ? userId : null, selectedGroup !== -1 ? selectedGroup : null)
                    .then(res => {
                        if (selectedUser.length === index + 1) {
                            this.setState({
                                isSnackbarOpen: true,
                                isAssignUserToGroupSuccessful: res.status === 200,
                                feedbackMessage: res.data.message,
                                loading: false,
                                selectedUser: [],
                                selectedGroup: -1
                            })    
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.setState({
                            isSnackbarOpen: true,
                            isAssignUserToGroupSuccessful: false,
                            feedbackMessage: err.response.data.message,
                            loading: false
                        })
                    })
            })
        } else {
            this.setState({
                isSnackbarOpen: true,
                isAssignUserToGroupSuccessful: false,
                feedbackMessage: 'Select Group to Assign User.',
                loading: false
            })
        }
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    render() {
        const { groupList, userList, selectedGroup, selectedUser, isSnackbarOpen, isAssignUserToGroupSuccessful, feedbackMessage, loading } = this.state
        return (
            <Box style={ containerStyles }>
                { loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <Typography
                        component='h1'
                        variant='h4'
                        align='center'
                        display='block'
                        style={{
                            color: '#505050',
                            marginBottom: '1rem'
                        }}
                    >
                        { 'Assign User To Group' }
                    </Typography>

                    <form onSubmit={ this._onSubmit } style={ formStyles }>
                        <Grid container={ true } spacing={ 3 }>
                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel required={ true }>{ 'Select User' }</InputLabel>
                                {/* <StyledSelectMenuVersion2
                                    menuItems={[ { value: -1, label: 'None' }, ...userList ]}
                                    label='Select User'
                                    name='selectedUser'
                                    required={ true }
                                    value={ selectedUser }
                                    onChange={ this._onChange }
                                /> */}
                                <StyledMultipleChipSelect
                                    menuItems={[ ...userList ]}
                                    label='Select User'
                                    name='selectedUser'
                                    required={ true }
                                    value={ selectedUser }
                                    onChange={this._onChange}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <InputLabel required={ true }>{ 'Select Group' }</InputLabel>
                                <StyledSelectMenuVersion2
                                    menuItems={[ { value: -1, label: 'None' }, ...groupList ]}
                                    label='Select Group'
                                    name='selectedGroup'
                                    required={ true }
                                    value={ selectedGroup }
                                    onChange={this._onChange}
                                />
                            </Grid>

                            <Grid item={ true } xs={ 12 } sm={ 12 }>
                                <StyledSubmitButton
                                    type='submit'
                                    fullWidth={ true }
                                    variant='contained'
                                >
                                    { 'Assign' }
                                </StyledSubmitButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isAssignUserToGroupSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '50%',
    padding: '32px'
}

const formStyles = {
    padding: '32px 0px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
}

export default AssignUserToGroup