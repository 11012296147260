// Import Third-party Packages
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import Hidden from '@material-ui/core/Hidden'
import { Link as MuiLink } from '@material-ui/core/'
import StyledSubmitButton from '../components/common/StyledSubmitButton'
import StyledSnackbar from '../components/common/StyledSnackbar'

// Import Assets
import traceImage from '../assets/trace_image.jpg'
import traceLogo from '../assets/trace_logo.png'

// Import Actions & Methods
import { handleLogin, setAuthError, forgotPassword } from '../store/actions/authActions'

class TraceLogin extends React.PureComponent {
    state = {
        email: '',
        password: '',
        errorEmail: '',
        errorPassword: '',

        isDialogOpen: false,
        emailForForgotPassword: '',
        errorEmailForForgotPassword: '',

        isSnackbarOpen: false,
        isForgotPasswordSuccessful: false,
        feedbackMessage: '',
        loading: false,
    }

    // Handle on Change
    handleOnChange = event => {
        this.setState({ [ event.target.name ]: event.target.value })

        // Set Error empty if exists
        const { authError } = this.props
        const { errorEmail, errorPassword, errorEmailForForgotPassword } = this.state
        if(event.target.name === 'email' && errorEmail) {
            this.setState({ errorEmail: '' })
        }

        if(event.target.name === 'password' && errorPassword) {
            this.setState({ errorPassword: '' })
        }

        if(event.target.name === 'emailForForgotPassword' && errorEmailForForgotPassword) {
            this.setState({ errorEmailForForgotPassword: '' })
        }        

        if(authError) {
            this.props.dispatch( setAuthError('') )
        }
    }

    // Handle On Submit
    handleOnSubmit = event => {
        event.preventDefault()

        let { email, password } = this.state

        // Validate Email & Password
        email = email.trim()
        const validateEmail = this._validateEmail(email)
        const validatePassword = this._validatePassword(password)
        if(validateEmail.success && validatePassword.success) {
            this.props.dispatch( handleLogin({ email, password }) )

        } else {
            this.setState({ errorEmail: validateEmail.message, errorPassword: validatePassword.message })
        }
    }

    ///////////////////////
    // Utility Functions //
    ///////////////////////

    // Validate email
    _validateEmail = email => {
        email = email.trim()
        const verdict = { success: false, message: '' }

        if (email) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                verdict.success = true
                verdict.message = ''

            } else {
                verdict.success = false
                verdict.message = 'Invalid email address.'
            }

        } else {
            verdict.success = false
            verdict.message = 'Required field.'
        }

        return verdict
    }

    _validatePassword = password => {
        const verdict = { success: false, message: '' }

        if (password) {
            if (password.length < 6) {
                verdict.success = false
                verdict.message = 'Password must be atleast 6 digit!'

            } else {
                verdict.success = true
                verdict.message = ''
            }

        } else {
            verdict.success = false
            verdict.message = 'Required field.'
        }

        return verdict
    }

    // Handle forgot password to open dialog
    _handleForgotPassword = () => {
        this.setState({ isDialogOpen: true })
    }

    // Handle Dialog Close
    _handleDialogClose = () => {    
        this.setState({ isDialogOpen: false })
    }

    // Handle email address for forgot password
    _onForgotPasswordSubmit = event => {
        event.preventDefault()
        this.setState({ loading: true })

        let { emailForForgotPassword } = this.state

        // Validate Email & Password
        emailForForgotPassword = emailForForgotPassword.trim()
        const validateEmail = this._validateEmail(emailForForgotPassword)
        if (validateEmail.success) {
            
            forgotPassword({ email: emailForForgotPassword })
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        isSnackbarOpen: true,
                        isForgotPasswordSuccessful: res.status === 200,
                        feedbackMessage: res.message,
                        loading: false,
                        emailForForgotPassword: '',
                        errorEmailForForgotPassword: '',                        
                    })
                } else {
                    this.setState({
                        isSnackbarOpen: true,
                        isForgotPasswordSuccessful: false,
                        feedbackMessage: res.message,
                        loading: false,
                        errorEmailForForgotPassword: res.message
                    })
                }
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isForgotPasswordSuccessful: false,
                    feedbackMessage: err.message,
                    loading: false,
                    errorEmailForForgotPassword: err.message
                })
            })

        } else {
            this.setState({ errorEmailForForgotPassword: validateEmail.message })
        }
    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    render() {
        const { authError } = this.props
        const { errorEmail, errorPassword, isDialogOpen, errorEmailForForgotPassword, isSnackbarOpen, feedbackMessage, isForgotPasswordSuccessful, loading } = this.state

        return (
            <Box
                display='flex'
                flexDirection='row'
                height='100vh'
                width='100%'
                alignItems='center'
                justifyContent='space-evenly'
                overflow='hidden'
            >
                <Hidden smDown={ true }>
                    <Box width='400px' height='400px'>
                        <CardMedia
                            image={ traceImage }
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                </Hidden>
                { loading &&
                    <CircularProgress />
                }

                <Box // RIGHT BOX (LOGIN PANEL)
                    display='flex'
                    flexDirection='column'
                    width='400px'
                    textAlign='left'
                >
                    <Box width='100%' mb='24px'>
                        <Box width='100%' display='inline-flex' alignItems='center'>
                            <CardMedia image={ traceLogo } style={{ width: '24px', height: '40px', marginBottom: '2px' }} />
                            <Typography component='h1' variant='h3' style={{ marginLeft: '4px' }}>{ 'Trace' }</Typography>
                        </Box>
                    </Box>

                    <Box width='100%'>
                        <Typography component='h2' variant='h5' style={{ fontSize: '30px' }}>{ 'Log In.' }</Typography>
                    </Box>

                    { authError &&
                        <Box width='100%'>
                            <Typography variant='caption' style={{ color: '#f44336' }}>{ authError }</Typography>
                        </Box>
                    }

                    <Box width='100%' mt='1rem'>
                        <form onSubmit={ this.handleOnSubmit }>
                            <Box marginBottom='1rem'>
                                <Typography variant='h6'>{ 'Email' }</Typography>

                                <StyledTextField
                                    error={ authError || errorEmail ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='email'
                                    type='text'
                                    autoComplete='email'
                                    onChange={ this.handleOnChange }
                                    helperText={ errorEmail ? errorEmail : null }
                                    placeholder='Enter your email here'
                                />
                            </Box>

                            <Box marginBottom='1rem'>
                                <Typography variant='h6'>{ 'Password' }</Typography>
                                
                                <StyledTextField
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='password'
                                    type='password'
                                    autoComplete='current-password'
                                    onChange={ this.handleOnChange }
                                    error={ authError || errorPassword ? true : false }
                                    helperText={ errorPassword ? errorPassword : null }
                                    placeholder='Enter your password here'
                                />
                            </Box>

                            <StyledButton
                                type='submit'
                                fullWidth={ true }
                                variant='contained'
                            >
                                { 'Log In' }
                            </StyledButton>
                        </form>

                        <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
                            <Typography variant='body1' align='center' >
                                { `Don't have an account yet?` }
                            </Typography>

                            <Link to='/signup' style={{ textDecoration: 'none', ...attributionLinkStyles, color: '#2ddbac' }}>
                                <Typography
                                    variant='body1' display='block' align='center'
                                >
                                    { ' Sign Up' }
                                </Typography>
                            </Link>                            
                        </Box>

                        <Box width='100%' display='flex' justifyContent='center' alignItems='center' marginBottom={ '1rem' }>
                            <MuiLink onClick={ this._handleForgotPassword }>
                                <Typography
                                    variant='body2' display='block' align='center' style={{ attributionLinkStyles, cursor: 'pointer' }}
                                >
                                    { 'Forgot Password?' }
                                </Typography>
                            </MuiLink>
                        </Box>

                        <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
                            <Typography
                                variant='body2' display='block' align='center' style={ attributionTextStyles }
                            >
                                { 'Developed By | ' }
                            </Typography>

                            <MuiLink href='https://barikoi.com' target='_blank' rel='noopener noreferrer'>
                                <Typography
                                    variant='body2' display='block' align='center' style={ attributionLinkStyles }
                                >
                                    { 'Barikoi Technologies Limited' }
                                </Typography>
                            </MuiLink>
                        </Box>
                    </Box>
                </Box>
                <Dialog
                    open={ isDialogOpen }
                    onClose={ this._handleDialogClose }
                    aria-labelledby="alert-dialog-title"
                    fullWidth={true}
                    disableBackdropClick={true}
                    scroll={'paper'}
                >
                    <DialogTitle id="alert-dialog-title">
                        { `Forgot Password?` }                        
                    </DialogTitle>
                    <DialogContent dividers={'paper'}>
                        <Box marginBottom='1rem'>
                            <Typography variant='h6'>{ 'Email' }</Typography>
                            
                            <StyledTextField
                                error={ errorEmailForForgotPassword ? true : false }
                                variant='outlined'
                                margin='none'
                                size='small'
                                fullWidth={ true }
                                name='emailForForgotPassword'
                                type='text'
                                autoComplete='email'
                                onChange={ this.handleOnChange }
                                helperText={ errorEmailForForgotPassword ? errorEmailForForgotPassword : null }
                                placeholder='Enter your email here'
                            />
                        </Box>                        
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={ this._handleDialogClose } color="primary">
                            Cancel
                        </Button>                        
                        <StyledSubmitButton                                    
                            fullWidth={ false }
                            variant='contained'
                            onClick={ this._onForgotPasswordSubmit }
                        >
                            { 'Submit' }
                        </StyledSubmitButton>
                    </DialogActions>
                </Dialog>
                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isForgotPasswordSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const attributionTextStyles = {
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '10px'
}


const attributionLinkStyles = {
    marginLeft: '4px',
    fontSize: '11px'
}

// JSS Styled Components
const StyledTextField = withStyles({
    root: {
        '& .MuiFormLabel-root': { // initial form label color
            color: 'grey'
        },
        '& label.Mui-focused': { // focused form label color
            color: 'black'
        },
        '& .MuiOutlinedInput-root': { // initial outline color
            width: '100%',
            '& fieldset': {
                borderColor: 'rgba(41, 194, 153, 0.8)'
            },
            '&:hover fieldset': {  // hover outline color
                borderColor: 'rgba(41, 194, 153, 1)'
            },
            '&.Mui-focused fieldset': { // focused outline color
                borderColor: 'rgba(41, 194, 153, 0.8)'
            }
        }
    }
})(TextField)

const StyledButton = withStyles({
    root: {
        marginBottom: '1rem',
        boxShadow: 'none',
        backgroundColor: 'rgba(41, 194, 153, 0.8)',
        '&:hover': {
            backgroundColor: 'rgba(41, 194, 153, 1)',
            borderColor: '#0062cc',
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf'
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
    label: {
        paddingTop: '4px',
        color: '#FFFFFF'
    }
})(Button)

const mapStateToProps = state => ({ authError: state.app.auth.error.message })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TraceLogin)