import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import StyledDataGrid from '../../common/StyledDataGrid'

class UserDetails extends React.PureComponent {
  render() {
    const { open, title, onClose, userDetails } = this.props

    return (
      <Dialog
        open={ open }
        onClose={ onClose }
        fullWidth={ true }
        maxWidth='md'
      >
        <DialogTitle
          disableTypography={ true }
          style={{
            padding: '8px 12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant='h6' style={{ padding: '0px 8px' }}>{ title }</Typography>
            {
              onClose ?
                <IconButton onClick={ onClose }>
                  <CloseIcon />
                </IconButton>
                :
                null
            }
        </DialogTitle>

        <DialogContent dividers={ true } style={ contentStyles }>
          <Paper variant='outlined' style={{ flexGrow: 1 }}>
            <tbody>
              <tr>
                <td style={{ padding: '8px', fontWeight: 800 }}>{ 'Name:' }</td>
                <td style={{ padding: '8px' }}>{ userDetails.name }</td>
              </tr>

              <tr>
                <td style={{ padding: '8px', fontWeight: 800 }}>{ 'E-mail:' }</td>
                <td style={{ padding: '8px' }}>{ userDetails.email }</td>
              </tr>

              <tr>
                <td style={{ padding: '8px', fontWeight: 800 }}>{ 'Phone:' }</td>
                <td style={{ padding: '8px' }}>{ userDetails.phone }</td>
              </tr>
            </tbody>
          </Paper>

          <Paper variant='outlined' style={{ padding: '16px', maxHeight: '50vh', flexGrow: 1 }}>
            <StyledDataGrid
              title={ 'Attendance History' }
              columns={[
                  { field: 'name', headerName: 'Name', flex: 1 },
                  { field: 'in_time', headerName: 'In Time', flex: 1 },
                  { field: 'out_time', headerName: 'Out Time', flex: 1 }
              ]}
              rows={[]}
              onRowClick={ () => null }
              onActionClick={ () => null }
            />
          </Paper>
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disableElevation={ true }
            onClick={ onClose }
            style={{
              color: '#f5f5f5',
              padding: '4px 8px',
              fontWeight: 800
            }}
          >
            { 'Close' }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

// JSS Styles
const contentStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '16px'
}

// Prop Types
UserDetails.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  userDetails: PropTypes.object
}

UserDetails.defaultProps = {
  open: false,
  title: 'User Details',
  onClose: () => null,
  userDetails: {}
}

export default UserDetails