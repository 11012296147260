import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

class GroupWiseCountAccordion extends React.PureComponent {
    render() {
        const { id, title, list } = this.props

        return (
            <Accordion elevation={ 4 }>
                <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-controls={ id }
                    id={ id }
                >
                    <Typography
                        variant='h6'
                        style={{ color: '#505050' }}
                    >
                        { title }
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <List style={{ width: '100%', maxHeight: '240px', overflow: 'auto' }}>
                        {
                            list.map((g, i) =>
                                <ListItem key={ i } style={{ background: i % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>
                                    <ListItemText
                                        primary={ g.group_name }
                                        primaryTypographyProps={{
                                            style: {
                                                color: '#505050',
                                                fontSize: '1.2rem'
                                            }
                                        }}
                                    />
                                    <ListItemSecondaryAction
                                        style={{
                                            marginRight: '32px',
                                            color: '#6a5acd',
                                            fontSize: '1.2rem',
                                            fontWeight: 600
                                        }}
                                    >
                                        { g.count }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
        )
    }
}

// Prop Types
GroupWiseCountAccordion.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    list: PropTypes.array
}

GroupWiseCountAccordion.defaultProps = {
    id: 'accordion-list',
    title: '',
    list: []
}

export default GroupWiseCountAccordion