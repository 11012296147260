import React from 'react'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

class StyledSubmitButton extends React.PureComponent {
    render() {
        return (
            <StyledButton { ...this.props }>{ this.props.children }</StyledButton>
        )
    }
}

const StyledButton = withStyles({
    root: {
        marginBottom: '1rem',
        boxShadow: 'none',
        backgroundColor: 'rgba(41, 194, 153, 0.8)',
        '&:hover': {
            backgroundColor: 'rgba(41, 194, 153, 1)',
            borderColor: '#0062cc',
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf'
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
    label: {
        paddingTop: '4px',
        color: '#FFFFFF',
        fontWeight: 600
    }
})(Button)

export default StyledSubmitButton