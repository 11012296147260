import * as ActionTypes from './actionTypes'

// Dispatch `SET_IS_SIDE_PANEL_OPEN`
export function setIsSidePanelOpen(isSidePanelOpen) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_IS_SIDE_PANEL_OPEN, payload: { isSidePanelOpen } })
    }
}

// Dispatch `SET_SELECTED_TAB`
export function setSelectedTab(selectedTab) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: { selectedTab } })
    }
}