import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import StyledInput from '../../common/StyledInput'
import StyledSubmitButton from '../../common/StyledSubmitButton'
import StyledSnackbar from '../../common/StyledSnackbar'
import StyledLinearProgress from '../../common/StyledLinearProgress'

// Import Actions & Methods
import { validateEmail, validatePassword, validatePhoneNumber } from '../../../utils/utils'
import { addNewUser, addBulkNewUser } from '../../../store/actions/companyDashboardActions'
import StyledTab from '../../common/StyledTab'
// import { Typography } from '@material-ui/core'

class AddUser extends React.PureComponent {
    state = {
        name: '',
        phone: '',
        email: '',
        password: '',
        error: {},
        isSnackbarOpen: false,
        isAddUserSuccessful: false,
        feedbackMessage: '',
        loading: false,
        tabIndex: 0,
        docFile: null
    }

    // Handle On Change
    _onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // Handle On Submit
    _onSubmit = e => {
        e.preventDefault()
        const { name, phone, email, password, error } = this.state

        // Validate Email
        const emailValidation = validateEmail(email)
        const passwordValidation = validatePassword(password)
        const phoneValidation = validatePhoneNumber(phone)
        if (emailValidation.success && passwordValidation.success && phoneValidation.success) {
            // Validation Success!!
            // Set Loading
            this.setState({ loading: true })

            const company_id = localStorage.getItem('companyId')

            // Add New User
            addNewUser({ name, phone, email, password, company_id })
                .then(res => {
                    this.setState({
                        isSnackbarOpen: true,
                        isAddUserSuccessful: res.status === 200,
                        feedbackMessage: res.message
                    })

                    // Set Loading
                    this.setState({ loading: false })
                })
                .catch(err => {
                    console.error(err)
                    console.log({ err })
                    this.setState({
                        isSnackbarOpen: true,
                        isAddUserSuccessful: false,
                        feedbackMessage: err.response.data.message || err.message,
                        loading: false
                    })
                })

            // Set Errors
            this.setState({ error: {} })

        } else {
            this.setState({
                error: {
                    ...error,
                    email: emailValidation.message,
                    password: passwordValidation.message,
                    phone: phoneValidation.message
                }
            })
        }
    }

    // Handle on Submit Bulk User
    _onSubmitBulkUser = e => {
        e.preventDefault()

        // Set Loading
        this.setState({ loading: true })
        
        const { docFile } = this.state

        if (docFile && docFile[0]) {
            addBulkNewUser(docFile[0])
            .then(res => {
                if (res.status === 201) {
                    this.setState({
                        isSnackbarOpen: true,
                        isAddUserSuccessful: res.status === 201,
                        feedbackMessage: res.message,
                        docFile: null
                    })

                    // Set Loading
                    this.setState({ loading: false })

                } else {
                    this.setState({
                        isSnackbarOpen: true,
                        isAddUserSuccessful: false,
                        feedbackMessage: 'Something went wrong.',
                        loading: false
                    })
                }
        })
            .catch(err => {
                console.error(err)
                this.setState({
                    isSnackbarOpen: true,
                    isAddUserSuccessful: false,
                    feedbackMessage: err.message,
                    loading: false
                })
            })
        
            // Set Errors
            this.setState({ error: {} })    
        } else {
            this.setState({
                isSnackbarOpen: true,
                isAddUserSuccessful: false,
                feedbackMessage: 'Something went wrong.',
                loading: false
            })
        }

    }

    // Handle On Snackbar Close
    _onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.setState({ isSnackbarOpen: false })
    }

    // Hanlde Tab Index Value
    _handleTabIndex = (e, value) => {
        this.setState({ tabIndex: value })
    }

    // Handle File Upload Change
    _onFileChange = e => {
        this.setState({ docFile: [e.target.files[0]] })
    }

    render() {
        const { name, phone, email, password, error, isSnackbarOpen, isAddUserSuccessful, feedbackMessage, loading, tabIndex } = this.state
        return (
            <Box style={ containerStyles }>
                {loading &&
                    <StyledLinearProgress />
                }

                <Paper elevation={ 4 } style={ paperStyles }>
                    <StyledTab
                        tabIndex={ tabIndex }
                        handleTabIndex={ this._handleTabIndex }
                        labels={[`Add New User`, `Import Bulk Users`]}
                    />
                    {
                        tabIndex === 0 ?
                            (
                                <form onSubmit={ this._onSubmit } style={ formStyles }>
                                    <Grid container={ true } spacing={3}>
                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <InputLabel required={ true }>{ 'Name' }</InputLabel>
                                            <StyledInput
                                                variant='outlined'
                                                margin='none'
                                                size='small'
                                                fullWidth={ true }
                                                name='name'
                                                type='text'
                                                value={ name }
                                                onChange={ this._onChange }
                                                placeholder='Enter name...'
                                                inputProps={{
                                                    style: {
                                                        color: '#505050',
                                                        fontSize: '16px'
                                                    }
                                                }}
                                                required={ true }
                                            />
                                        </Grid>

                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <InputLabel required={ true }>{ 'Phone' }</InputLabel>
                                            <StyledInput
                                                error={ error.phone ? true : false }
                                                variant='outlined'
                                                margin='none'
                                                size='small'
                                                fullWidth={ true }
                                                name='phone'
                                                type='text'
                                                value={ phone }
                                                onChange={ this._onChange }
                                                placeholder='Enter phone number...'
                                                helperText={ error.phone ? error.phone : null }
                                                inputProps={{
                                                    style: {
                                                        color: '#505050',
                                                        fontSize: '16px'
                                                    }
                                                }}
                                                required={ true }
                                            />
                                        </Grid>

                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <InputLabel required={ true }>{ 'Email' }</InputLabel>
                                            <StyledInput
                                                error={ error.email ? true : false }
                                                variant='outlined'
                                                margin='none'
                                                size='small'
                                                fullWidth={ true }
                                                name='email'
                                                type='text'
                                                autoComplete='email'
                                                value={ email }
                                                onChange={ this._onChange }
                                                helperText={ error.email ? error.email : null }
                                                placeholder='Enter email...'
                                                inputProps={{
                                                    style: {
                                                        color: '#505050',
                                                        fontSize: '16px'
                                                    }
                                                }}
                                                required={ true }
                                            />
                                        </Grid>

                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <InputLabel required={ true }>{ 'Password' }</InputLabel>
                                            <StyledInput
                                                error={ error.password ? true : false }
                                                variant='outlined'
                                                margin='none'
                                                size='small'
                                                fullWidth={ true }
                                                name='password'
                                                type='password'
                                                value={ password }
                                                onChange={ this._onChange }
                                                helperText={ error.password ? error.password : null }
                                                placeholder='Enter password...'
                                                inputProps={{
                                                    style: {
                                                        color: '#505050',
                                                        fontSize: '16px'
                                                    }
                                                }}
                                                required={ true }
                                            />
                                        </Grid>

                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <StyledSubmitButton
                                                type='submit'
                                                fullWidth={ true }
                                                variant='contained'
                                            >
                                                {'Add User'}
                                            </StyledSubmitButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            )
                            :
                            (
                                <form onSubmit={ this._onSubmitBulkUser } style={ formStyles }>
                                    <Grid container={ true } spacing={ 3 }>
                                        <Grid item={true} xs={12} sm={12}>
                                            <Box>
                                                <input
                                                    id="xlxs-files"
                                                    multiple
                                                    type="file"
                                                    onChange={this._onFileChange}
                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item={ true } xs={ 12 } sm={ 12 }>
                                            <StyledSubmitButton
                                                type='submit'
                                                fullWidth={ true }
                                                variant='contained'
                                            >
                                                { 'Add User' }
                                            </StyledSubmitButton>
                                        </Grid>
                                    </Grid>
                                    {/* <Box>
                                        <Typography>
                                            { 'Coming soon.' }
                                        </Typography>
                                    </Box> */}
                                </form>
                            )
                    }

                </Paper>

                <StyledSnackbar
                    open={ isSnackbarOpen }
                    isSuccessful={ isAddUserSuccessful }
                    message={ feedbackMessage }
                    onClose={ this._onSnackbarClose }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    background: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const paperStyles = {
    width: '50%',
    padding: '32px',
    height: '548px'
}

const formStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    // marginTop: '2rem'
}

export default AddUser