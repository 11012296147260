// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

class BarikoiLogo extends React.PureComponent {
    state = {
        variant: 'h5',
        width: '92px',
        fontWeight: '600',
        color: '#fff'
    }

    componentDidMount() {
        const { size, theme } = this.props

        this._setSizeBasedOnProp(size)
        this._setTheme(theme)
    }

    componentDidUpdate(prevProps) {
        const { size, theme } = this.props

        // If Size Changes in Props
        if(prevProps.size !== size) {
            this._setSizeBasedOnProp(size)
        }

        // If Theme Changes in Props
        if(prevProps.theme !== theme) {
            this._setTheme(theme)
        }
    }

    // Set Component Size Based on 'size' prop
    _setSizeBasedOnProp = size => {
        let newState = {}

        switch(size) {
            case 'small':
                newState = { variant: 'h6', width: '74px', fontWeight: '500' }
                break
            case 'medium':
                newState = { variant: 'h5', width: '92px', fontWeight: '600' }
                break
            case 'large':
                newState = { variant: 'h4', width: '128px', fontWeight: '700' }
                break
            default:
                newState = { variant: 'h5', width: '92px', fontWeight: '600' }
        }

        this.setState({ ...newState })
    }

    // Set Component Theme based on 'theme' prop
    _setTheme = theme => {
        let newColor = ''

        switch(theme) {
            case 'light':
                newColor = '#fff'
                break
            case 'dark':
                newColor = '#292e35'
                break
            default:
                newColor = '#fff'
        }

        this.setState({ color: newColor })
    }

    render() {
        const { variant, width, fontWeight, color } = this.state

        return (
            <Container disableGutters={ true } style={{ ...logoContainerStyles, width }} fixed={ true }>
                <Typography variant={ variant } style={{ ...logoBariTypographyStyles, fontWeight, color }}>{ 'Bari' }</Typography>
                <Typography variant={ variant } style={{ ...logoKoiTypographyStyles, fontWeight }}>{ 'koi' }</Typography>
            </Container>
        )
    }
}

// JSS Styles
const logoContainerStyles = {
    margin: '0px',
    padding: '4px'
}

const logoBariTypographyStyles = {
    display: 'inline-block',
    fontFamily: 'Rubik, sans-serif'
}

const logoKoiTypographyStyles = {
    display: 'inline-block',
    position: 'absolute',
    color: '#2ddbac',
    fontFamily: 'Rubik, sans-serif'
}

// PropTypes
BarikoiLogo.propTypes = {
    size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
    theme: PropTypes.oneOf([ 'light', 'dark' ])
}

BarikoiLogo.defaultProps = {
    size: 'medium',
    theme: 'light'
}

export default BarikoiLogo