import React from 'react'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

class StyledIconButton extends React.PureComponent {
    render() {
        return (
            <StyledIconBtn { ...this.props }>{ this.props.children }</StyledIconBtn>
        )
    }
}

const StyledIconBtn = withStyles({
    root: {
        padding: 0,
        '&:hover': {
            padding: 0,
            background: 'none'
        },
    },
    label: {
        paddingTop: '4px',
        color: '#2ddbac',
        fontWeight: 600,
    }
})(IconButton)

export default StyledIconButton