import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'

class StyledSelectMenu extends React.PureComponent {
    render() {
        const { menuItems, value, onChange, required, name, multiple, selectProps, ...restProps } = this.props
        
        return (
            <FormControl
                variant='outlined'
                fullWidth={ true }
                margin='dense'
                size='small'
                required={ required }
                style={{ marginTop: '4px' }}
                { ...restProps }
            >
                <StyledSelect
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        getContentAnchorEl: null,
                        style: { maxHeight: '320px' }
                    }}
                    input={ <StyledOutlinedInput notched={ true } /> }
                    inputProps={{ name, required }}
                    multiple={ multiple }
                    value={ value }
                    onChange={ onChange }
                    { ...selectProps }
                    >
                    { (!menuItems || menuItems.length === 0) &&
                        <StyledMenuItem dense={ true } value=''><em>{ 'None' }</em></StyledMenuItem>
                    }

                    {
                        menuItems.map((item, index) =>
                            <StyledMenuItem
                                key={ index }
                                dense={ true }
                                value={ item.value ? item.value : item }
                            >
                                { item.label ? item.label : item }
                            </StyledMenuItem>
                        )
                    }
                </StyledSelect>
            </FormControl>
        )
    }
}

const StyledSelect = withStyles({
    icon: {
        color: 'rgba(45, 219, 172, 1)'
    },
    outlined: {
        color: '#505050',
        fontSize: '14px'
    }
})(Select)

const StyledMenuItem = withStyles({
    root: {
        fontSize: '12px',
        color: 'rgba(56, 64, 78, 1)'
    }
})(MenuItem)

const StyledOutlinedInput = withStyles({
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'rgba(45, 219, 172, 0.4)'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'rgba(45, 219, 172, 0.8)'
        }
    }
})(OutlinedInput)

// Prop Types
StyledSelectMenu.propTypes = {
    menuItems: PropTypes.array,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.array ]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    selectProps: PropTypes.object
}

StyledSelectMenu.defaultProps = {
    menuItems: [],
    value: '',
    onChange: () => null,
    required: false,
    name: 'select',
    multiple: false,
    selectProps: {}
}

export default StyledSelectMenu