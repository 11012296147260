import React from 'react'
import PropTypes from 'prop-types'

// Import Components
// import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
// import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import UpdateUserRoutePoint from '../AdminDashboard/MainBody/UpdateUserRoutePoint'
// import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock'

export default class StyledModal extends React.PureComponent {
    render() {
        const { isModalOpen, closeModal, routePointInfo } = this.props
        return (
            <Box>
                <Dialog
                    open={ isModalOpen }
                    onClose={ closeModal }
                    fullWidth
                    disableBackdropClick
                    maxWidth='lg'
                    // maxWidth={clickedStatus === 'AREA' ? 'sm' : 'lg'}
                    TransitionComponent={ Transition }
                >
                    <DialogTitle style={{ color: 'red' }}>
                        <IconButton onClick={ closeModal } style={{ ...positonTopRight }}>
                            <CancelRoundedIcon color='error' fontSize='large' />
                        </IconButton>
                        {/* <Box style={{ textTransform: 'capitalize' }}>
                            <Typography variant='h6' style={{ fontWeight: 500 }}>
                                { title }
                            </Typography>
                        </Box> */}
                    </DialogTitle>
                    <DialogContent >
                        <UpdateUserRoutePoint routePointInfo={ routePointInfo } />
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
}

// JSS Styles
const positonTopRight = {
    position: 'absolute',
    right: '4px',
    top: '4px',
    // color: theme.palette.grey[500],
}

// const CustomDialogActions = withStyles({
//     root: {
//         justifyContent: 'center',
//         padding: 0
//     }
// })(DialogActions)

// Prop Types
StyledModal.propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    title: PropTypes.string,
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})
