import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'

class StyledDateTimePicker extends React.PureComponent {
    render () {
        const { value, onChange, inputProps, ...restProps } = this.props

        return (
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <DatePicker
                    autoOk={ true }
                    variant='inline'
                    inputVariant='outlined'
                    value={ value }
                    // format='MMM do, yyyy - hh:mm a'
                    format='dd MMM yyyy'
                    disableFuture={ true }
                    onChange={ onChange }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton size='small'>
                                    <InsertInvitationIcon fontSize='small' style={{ color: 'rgba(45, 219, 172, 1)' }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            height: '40px',
                            color: 'rgb(80, 80, 80)'
                        },
                        ...inputProps
                    }}
                    style={{ margin: '4px 0px', width: '100%' }}
                    { ...restProps }
                />
            </MuiPickersUtilsProvider>
        )
    }
}

// Prop Types
StyledDateTimePicker.propTypes = {
    value: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    inputProps: PropTypes.object,
    onChange: PropTypes.func
}

StyledDateTimePicker.defaultProps = {
    value: new Date(),
    inputProps: {},
    onChange: () => null
}

export default StyledDateTimePicker